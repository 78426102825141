import React, { Component } from "react";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  List,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import DialogTitle from "./myDialogTitle";
import LoadingListItem from "../loadingListItem";
import { IReportDetail, IReportDetailsFilter } from "../../types";
import ReportListItem from "../../containers/reportListItemContainer";
import ReportListAdItem from "../reportListAdItem";

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "& h2": {
        fontSize: "20px",
      },
    },
  });
interface IStyles extends WithStyles<typeof styles> {}
interface IClusterDialogProps {
  readonly isOpen: boolean;
  readonly reports: IReportDetail[];
  readonly filter: IReportDetailsFilter;
  closeDialog: () => void;
  getReportDetails: () => void;
}
type IProps = IClusterDialogProps & IStyles;

class ClusterDialog extends Component<IProps> {
  renderItem = () => {
    if (this.props.reports.length <= 0) {
      return [1, 2].map((v) => {
        return <LoadingListItem key={v} />;
      });
    }
    return this.props.reports.map((report, index) => {
      return (
        <React.Fragment>
          {index % 10 === 2 && (
            <ReportListAdItem listItemIndex={index} mode="mail" />
          )}

          {index % 10 === 7 && (
            <ReportListAdItem listItemIndex={index} mode="youtube" />
          )}
          <ReportListItem key={report.id} report={report} />
        </React.Fragment>
      );
    });
  };

  handleClose = () => {
    this.props.closeDialog();
  };

  render() {
    let fullScreen = false;
    if (window.innerWidth < 600) {
      fullScreen = true;
    }
    const reports = this.props.reports;
    const nextPage = this.props.filter.nextPage;
    return (
      <Dialog
        open={this.props.isOpen}
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        fullScreen={fullScreen}
        maxWidth="md"
        area-labelledby="cluster-dialog"
        onClose={this.handleClose}
      >
        <DialogTitle id="cluster-dialog-title" onClose={this.handleClose}>
          分蜂報告一覧
        </DialogTitle>
        <DialogContent style={{ height: "100%" }} id="scrollableDialog">
          <List>
            <InfiniteScroll
              dataLength={reports.length}
              next={() => {
                this.props.getReportDetails();
              }}
              hasMore={nextPage !== -1}
              loader={<div>Loading...</div>}
              scrollableTarget="scrollableDialog"
            >
              {this.renderItem()}
            </InfiniteScroll>
          </List>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ClusterDialog);
