import webAPI from '../apis/webAPI'
import actionCreatorFactory from 'typescript-fsa'
import { IRootState } from '../reducers/rootReducer'
import { IFormOptions } from '../types'
import { Dispatch } from 'redux'

type GetState = () => IRootState
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
const actionCreator = actionCreatorFactory()

export const getFormOptionsRequest = actionCreator('GET_FORM_OPTIONS_REQUEST')
export const getFormOptionsSuccess = actionCreator<IFormOptions>(
  'GET_FORM_OPTIONS_SUCCESS',
)
export const getFormOptionsError = actionCreator('GET_FORM_OPTIONS_ERROR')

export const getFormOptions = (): ThunkAction => async (dispatch: Dispatch) => {
  dispatch(getFormOptionsRequest())
  webAPI
    .get('/reports/option_list', {})
    .then(res => {
      const options: IFormOptions = {
        catchType: res.data.catch_type,
        timeZone: res.data.time_zone,
        weather: res.data.weather,
        hiveType: res.data.hive_type,
        hiveLocation: res.data.hive_location,
        using: res.data.using,
      }
      dispatch(getFormOptionsSuccess(options))
    })
    .catch(error => {
      console.log(error)
      dispatch(getFormOptionsError())
    })
}
