import actionCreatorFactory, { AnyAction } from 'typescript-fsa'
import { IRootState } from '../reducers/rootReducer'
import { Dispatch } from 'redux'
import webAPI from '../apis/webAPI'
import { getReportLocations } from './reportLocations'
import { closeDialog } from './clusterDialog'

type GetState = () => IRootState
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
const actionCreator = actionCreatorFactory()

export const deleteReportRequest = actionCreator('DELETE_REPORT_REQUEST')
export const deleteReportSuccess = actionCreator('DELETE_REPORT_SUCCESS')
export const deleteReportError = actionCreator('DELETE_REPORT_ERROR')
export const openDeleteDialog = actionCreator<string>('OPEN_DELETE_DIALOG')
export const closeDeleteDialog = actionCreator('CLOSE_DELETE_DIALOG')

export const deleteReport = (editKey: string): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const targetId = getState().deleteDialog.targetReportId
  dispatch(deleteReportRequest())
  const params = new FormData()
  params.append('edit_key', editKey)
  webAPI
    .delete(`/reports/${targetId}.json`, { data: params })
    .then(res => {
      dispatch(deleteReportSuccess())
      dispatch(getReportLocations() as AnyAction)
      dispatch(closeDialog())
    })
    .catch(error => {
      console.log(error)
      dispatch(deleteReportError())
    })
}
