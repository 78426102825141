import { IRootState } from '../reducers/rootReducer'
import { IReportDetail, IReportDetailsFilter } from '../types'
import actionCreatorFactory from 'typescript-fsa'
import { Dispatch } from 'redux'
import webAPI from '../apis/webAPI'

const PER_PAGE = 20

type GetState = () => IRootState
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
const actionCreator = actionCreatorFactory()

export const getReportDetailsRequest = actionCreator(
  'GET_REPORT_DETAILS_REQUEST',
)
export const getReportDetailsSuccess = actionCreator<IReportDetail[]>(
  'GET_REPORT_DETAILS_SUCCESS',
)
export const getReportDetailsError = actionCreator('GET_REPORT_DETAILS_ERROR')
export const setReportDetailsFilter = actionCreator<IReportDetailsFilter>(
  'SET_REPORT_DETAILS_FILTER',
)
export const setReportDetailsFilterIds = actionCreator<number[]>(
  'SET_REPORT_DETAILS_FILTER_IDS',
)
export const setReportDetailsFilterDate = actionCreator<{
  start: string
  end: string
}>('SET_REPORT_DETAILS_FILTER_DATE')
export const setReportDetailsNextPage = actionCreator<number>(
  'SET_REPORT_DETAILS_FILTER_NEXT_PAGE',
)

const createEndpointByFilter = (filter: IReportDetailsFilter): string => {
  let endpoint: string = ''
  if (filter.mode === 'ids') {
    const start = PER_PAGE * (filter.nextPage - 1)
    const end = start + PER_PAGE
    endpoint = `/reports/${filter.ids.slice(start, end).join(',')}.json`
  } else if (filter.mode === 'date') {
    endpoint = `/reports/details/${filter.range.start}/${filter.range.end}`
  }
  return endpoint
}

export const getReportDetails = (): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const filter = getState().reportDetails.filter
  const isRequesting = getState().reportDetails.isRequesting

  if (isRequesting) {
    return
  }

  if (filter.nextPage === -1) {
    return
  }

  const endpoint = createEndpointByFilter(filter)
  if (endpoint === '') {
    return
  }
  dispatch(getReportDetailsRequest())
  let params = {}
  if (filter.mode === 'date') {
    params = {
      page: filter.nextPage,
    }
  }
  webAPI
    .get(endpoint, { params })
    .then(res => {
      let nextPage
      if (filter.mode === 'date') {
        nextPage = res.data.last_page ? -1 : filter.nextPage + 1
      } else {
        nextPage =
          PER_PAGE * filter.nextPage >= filter.ids.length
            ? -1
            : filter.nextPage + 1
      }
      dispatch(setReportDetailsNextPage(nextPage))

      const result: IReportDetail[] = res.data.items
      dispatch(getReportDetailsSuccess(result))
    })
    .catch(error => {
      console.log(error)
      dispatch(getReportDetailsError())
      return
    })
}
