import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as actions from '../actions/reportList'
import { IReportDetail, IReportDetailsFilter } from '../types'

export type ReportListState = {
  readonly reports: IReportDetail[]
  readonly checkedInBounds: boolean
  readonly filter: IReportDetailsFilter
  readonly total: number
  readonly isRequesting: boolean
  readonly isError: boolean | null
}

const createDefaultListFilter = (): IReportDetailsFilter => ({
  nextPage: 0,
  mode: 'ids',
  ids: [],
  range: { start: '', end: '' },
})

const initialState: ReportListState = {
  reports: [],
  checkedInBounds: false,
  filter: createDefaultListFilter(),
  total: 0,
  isRequesting: false,
  isError: null,
}

export default reducerWithInitialState(initialState)
  .case(actions.getReportListRequest, (state: ReportListState) => ({
    ...state,
    isRequesting: true,
    isError: null,
  }))
  .case(
    actions.getReportListSuccess,
    (state: ReportListState, reports: IReportDetail[]) => ({
      ...state,
      reports: state.reports.concat(reports),
      isRequesting: false,
      isError: false,
    }),
  )
  .case(actions.getReportListError, (state: ReportListState) => ({
    ...state,
    isRequesting: false,
    isError: true,
  }))
  .case(
    actions.setReportListFilterDate,
    (state: ReportListState, range: { start: string; end: string }) => ({
      ...state,
      filter: {
        nextPage: 1,
        mode: 'date',
        ids: [],
        range,
      },
      reports: [],
    }),
  )
  .case(
    actions.setReportListFilterIds,
    (state: ReportListState, ids: number[]) => ({
      ...state,
      filter: {
        nextPage: 1,
        mode: 'ids',
        ids,
        range: { start: '', end: '' },
      },
      reports: [],
    }),
  )
  .case(
    actions.setReportListNextPage,
    (state: ReportListState, nextPage: number) => ({
      ...state,
      filter: {
        ...state.filter,
        nextPage,
      },
    }),
  )
  .case(
    actions.setReportListFilter,
    (state: ReportListState, filter: IReportDetailsFilter) => ({
      ...state,
      filter,
    }),
  )
  .case(
    actions.setCheckedInBounds,
    (state: ReportListState, checkedInBounds: boolean) => ({
      ...state,
      checkedInBounds,
    }),
  )
  .case(
    actions.setReportListTotal,
    (state: ReportListState, total: number) => ({
      ...state,
      total,
    }),
  )
  .build()
