import ThirdStep from '../../components/forms/thirdStep'
import { connect } from 'react-redux'
import { IRootState } from '../../reducers/rootReducer'
import { formValueSelector, change } from 'redux-form'
import { getSpamCheckAnswer } from '../../actions/spamCheck'
import { setCheckedImageDelete } from '../../actions/reportForm'

const selector = formValueSelector('report')
const mapStateToProps = (state: IRootState) => {
  return {
    description: selector(state, 'description'),
    image: selector(state, 'image'),
    checkedImageDelete: state.reportForm.checkedImageDelete,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearField: (field: string) => {
      dispatch(change('report', field, null))
    },
    getAnswer: () => {
      dispatch(getSpamCheckAnswer())
    },
    setCheckedImageDelete: (checked: boolean) => {
      dispatch(setCheckedImageDelete(checked))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThirdStep)
