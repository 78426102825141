import React, { Component } from 'react'
import { WithStyles, Theme, createStyles, withStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Header from '../../containers/headerContainer'

interface IState {}
interface IStyles extends WithStyles<typeof styles> {}
interface IAppProps {
  title: string
  src: string
}
type IProps = IAppProps & IStyles

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
  })

class IFramePage extends Component<IProps, IState> {
  render() {
    const { classes, src, title } = this.props
    return (
      <div className={classes.root}>
        <Header isMapPage={false} />
        <Container maxWidth="md">
          <Grid container={true} justify="center">
            <Grid item={true} xs={12}>
              <iframe
                title={title}
                src={src}
                width="100%"
                height="600"
                frameBorder="0"
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default withStyles(styles)(IFramePage)