import actionCreatorFactory from 'typescript-fsa'
import { IRootState } from '../reducers/rootReducer'
import { Dispatch } from 'redux'
import webAPI from '../apis/webAPI'
import { openEditDialog, setTargetReport } from './reportForm'

type GetState = () => IRootState
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
const actionCreator = actionCreatorFactory()

export const openEditConfirmDialog = actionCreator<string>(
  'OPEN_EDIT_CONFIRM_DIALOG',
)
export const closeEditConfirmDialog = actionCreator('CLOSE_EDIT_CONFIRM_DIALOG')
export const editConfirmRequest = actionCreator('EDIT_CONFIRM_REQUEST')
export const editConfirmSuccess = actionCreator('EDIT_CONFIRM_SUCCESS')
export const editConfirmError = actionCreator('EDIT_CONFIRM_ERROR')

export const checkEditKey = (editKey: string): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  dispatch(editConfirmRequest())
  const reportId = getState().editConfirm.targetReportId
  webAPI
    .get(`/reports/check_edit_key/${reportId}/${editKey}`, {})
    .then(res => {
      if (res.data.result) {
        const reports = getState().reportList.reports
        let report = reports.find(report => {
          return report.id === reportId
        })
        if (!report) {
          const clusterReports = getState().reportDetails.reportDetails
          report = clusterReports.find(report => {
            return report.id === reportId
          })
        }
        dispatch(editConfirmSuccess())
        dispatch(closeEditConfirmDialog())
        dispatch(setTargetReport(report))
        dispatch(openEditDialog(editKey))
      } else {
        dispatch(editConfirmError())
      }
    })
    .catch(error => {
      console.log(error)
      dispatch(editConfirmError())
    })
}
