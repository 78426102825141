import React, { Component } from 'react'
import {
  Dialog,
  createStyles,
  Theme,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Grid,
  Button,
} from '@material-ui/core'
import DialogTitle from './myDialogTitle'
import { withStyles, WithStyles } from '@material-ui/styles'
import FirstStep from '../../containers/forms/firstStepContainer'
import SecondStep from '../../containers/forms/secondStepContainer'
import ThirdStep from '../../containers/forms/thirdStepContainer'
import FourthStep from '../../containers/forms/fourthStepContainer'
import dayjs from 'dayjs'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 18,
    },
    content: {
      minWidth: '600px',
      overflowY: 'unset',
    },
    stepper: {
      fontSize: 16,
    },
    dialogTitle: {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: 'white',
    },
  })

interface IState {
  step: number
}
interface IStyles extends WithStyles<typeof styles> {}
interface IReportDialogProps {
  closeDialog: () => void
  postReport: (values: any) => void
  resetForm: () => void
  readonly isOpen: boolean
  readonly isSuccess: boolean | null
  readonly isError: boolean | null
}
type IProps = IReportDialogProps & IStyles

const steps = ['Step1', 'Step2', 'Step3', 'Step4', '投稿完了']

class ReportDialog extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      step: 0,
    }
    this.nextStep = this.nextStep.bind(this)
    this.prevStep = this.prevStep.bind(this)
  }

  nextStep = () => {
    this.setState({
      ...this.state,
      step: this.state.step + 1,
    })
  }

  prevStep = () => {
    this.setState({
      ...this.state,
      step: this.state.step - 1,
    })
  }

  onSubmit = (values: any) => {
    this.props.postReport(values)
    localStorage.setItem('report/nickname', values.nickname)
    localStorage.setItem('report/pref', values.pref)
    localStorage.setItem('report/city', values.city)
    this.nextStep()
  }

  handleClose = (event: React.SyntheticEvent<{}, Event>) => {
    if (
      this.state.step > 3 ||
      window.confirm('投稿フォームを閉じても良いですか？')
    ) {
      this.setState({
        ...this.state,
        step: 0,
      })
      this.props.closeDialog()
    }
  }

  renderStep = (step: number) => {
    if (step > 3 && this.props.isSuccess) {
      this.props.resetForm()
      return (
        <div>
          <h2>投稿ありがとうございます</h2>
          <p>
            以上で投稿は完了です。地図に投稿が反映されました。
            <br />
            自分の投稿を見るには、自分が投稿した地域の赤いマーカーをクリックするか、すべての投稿を読むボタンをクリックしてください。
          </p>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} style={{ textAlign: 'right' }}>
              <Button
                color="primary"
                variant="outlined"
                type="button"
                onClick={this.handleClose}
              >
                閉じる
              </Button>
            </Grid>
          </Grid>
        </div>
      )
    }
    if (step > 3 && this.props.isError) {
      return (
        <div>
          <h2>エラーが発生しました</h2>
          <p>
            投稿に失敗しました。
            <br />
            時間をおいて試してください。
          </p>
        </div>
      )
    }
    let initialValues = {}
    switch (step) {
      case 0:
        const nickname = localStorage.getItem('report/nickname')
        const pref = localStorage.getItem('report/pref')
        const city = localStorage.getItem('report/city')
        initialValues = {
          nickname,
          catchDate: dayjs().format('YYYY-MM-DD'),
          pref,
          city,
        }
        return (
          <FirstStep onSubmit={this.nextStep} initialValues={initialValues} />
        )
      case 1:
        return <SecondStep onSubmit={this.nextStep} prevStep={this.prevStep} />
      case 2:
        return <ThirdStep onSubmit={this.nextStep} prevStep={this.prevStep} />
      case 3:
        return <FourthStep onSubmit={this.onSubmit} prevStep={this.prevStep} />
    }
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    )
  }

  render() {
    const { classes } = this.props
    const { step } = this.state
    let fullScreen = false
    if (window.innerWidth < 600) {
      fullScreen = true
    }
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.handleClose}
        aria-labelledby="report-dialog"
        disableBackdropClick={true}
        fullScreen={fullScreen}
        className={classes.root}
      >
        <DialogTitle id="report-dialog-title" onClose={this.handleClose}>
          新規投稿
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Stepper activeStep={step} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>{this.renderStep(step)}</React.Fragment>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ReportDialog)
