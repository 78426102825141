import React from 'react'
import { FieldProps } from './form'
import Select from '@material-ui/core/Select'

const SelectField: React.SFC<FieldProps> = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <React.Fragment>
      <Select
        label={label}
        error={touched && error !== ''}
        {...input}
        {...custom}
      />
      {touched && (
        <div style={{ color: 'red', fontSize: '80%', marginLeft: '8px' }}>
          {error && <span>{error}</span>}
        </div>
      )}
    </React.Fragment>
  )
}

export default SelectField
