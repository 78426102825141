import ReportListItem from '../components/reportListItem'
import { connect } from 'react-redux'
import { IRootState } from '../reducers/rootReducer'
import { openDeleteDialog } from '../actions/deleteDialog'
import { openEditConfirmDialog } from '../actions/editConfirmDialog'

const mapStateToProps = (state: IRootState) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    openDeleteDialog: (id: string) => {
      dispatch(openDeleteDialog(id))
    },
    openEditConfirm: (id: string) => {
      dispatch(openEditConfirmDialog(id))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportListItem)
