import { Values, Errors } from './form'

const IMAGE_MAX_SIZE = 10000000
const required = (value: string): boolean => (value ? true : false)
const notEmpty = (value: string[]): boolean =>
  value && value.length > 0 ? true : false
const validate = (values: Values) => {
  const errors: Errors = {}
  // Step1
  if (!required(values.nickname)) {
    errors.nickname = '必須の項目です'
  }
  if (!required(values.catchDate)) {
    errors.catchDate = '日付を選択してください'
  }
  if (!required(values.timezone)) {
    errors.timezone = '選択してください'
  }
  if (!required(values.pref)) {
    errors.pref = '選択してください'
  }
  if (!required(values.city)) {
    errors.city = '選択してください'
  }
  // Step2
  if (!required(values.weather)) {
    errors.weather = '選択してください'
  }
  if (values.weather === 'other' && !required(values.weatherOther)) {
    errors.weatherOther = '必須の項目です'
  }
  if (!required(values.catchType)) {
    errors.catchType = '選択してください'
  }
  // 自然入居の場合
  if (values.catchType === 'cat01') {
    if (!required(values.hiveType)) {
      errors.hiveType = '選択してください'
    }
    if (values.hiveType === 'other' && !required(values.hiveTypeOther)) {
      errors.hiveTypeOther = '必須の項目です'
    }
    if (!required(values.hiveLocation)) {
      errors.hiveLocation = '選択してください'
    }
    if (
      values.hiveLocation === 'other' &&
      !required(values.hiveLocationOther)
    ) {
      errors.hiveLocationOther = '必須の項目です'
    }
    if (!notEmpty(values.using)) {
      errors.using = '選択してください'
    }
    if (
      notEmpty(values.using) &&
      values.using.includes('その他') &&
      !required(values.usingOther)
    ) {
      errors.usingOther = '必須の項目です'
    }
  }
  // Step3
  if (!required(values.description)) {
    errors.description = '必須の項目です'
  }
  if (values.image) {
    if (values.image.size >= IMAGE_MAX_SIZE) {
      errors.image = '画像ファイルサイズが大きすぎます。'
    }
  }
  // Step4
  if (!required(values.answer)) {
    errors.answer = '必須の項目です'
  }
  return errors
}

export default validate

export const confirmAnswer = (value: string) => (answer: string) => {
  if (value !== answer) {
    return '答えが違います'
  }
  return undefined
}
