import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import MyTextField from './textField'
import SelectField from './selectField'
import MultiSelect from './multiSelect'
import {
  Theme,
  createStyles,
  Grid,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
} from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/styles'
import validate from './validate'
import { IFormOptions } from '../../types'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 300,
      width: '60%',
    },
    selectField: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    inputLabel: {
      margin: theme.spacing(1),
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })

interface IState {}
interface IStyles extends WithStyles<typeof styles> {}
interface IStepProps {
  prevStep: () => void
  options: IFormOptions | null
  weather: string
  catchType: string
  hiveType: string
  hiveLocation: string
  using: string[]
}
type IProps = IStepProps & IStyles

class SecondStep extends Component<
  IProps & InjectedFormProps<{}, IProps>,
  IState
> {
  render() {
    const { handleSubmit, pristine, invalid, classes } = this.props
    let myPristine = pristine
    if (this.props.weather) {
      myPristine = false
    }
    const weather = this.props.options!.weather
    const catchType = this.props.options!.catchType
    const hiveType = this.props.options!.hiveType
    const hiveLocation = this.props.options!.hiveLocation
    const using = this.props.options!.using
    const weatherOther =
      this.props.weather === 'other' ? (
        <Grid item={true} xs={12}>
          <Field
            id="weatherOther"
            name="weatherOther"
            required={true}
            component={MyTextField}
            label="その他天気"
            placeholder="その他の天気を入力してください。"
            className={classes.textField}
          />
        </Grid>
      ) : null
    const isNatural = this.props.catchType === 'cat01'
    const hiveTypeOther =
      this.props.hiveType === 'other' ? (
        <Grid item={true} xs={12}>
          <Field
            id="hiveTypeOther"
            name="hiveTypeOther"
            required={true}
            component={MyTextField}
            label="その他の巣箱"
            placeholder="その他の巣箱を入力してください。"
            className={classes.textField}
          />
        </Grid>
      ) : null
    const hiveLocationOther =
      this.props.hiveLocation === 'other' ? (
        <Grid item={true} xs={12}>
          <Field
            id="hiveLocationOther"
            name="hiveLocationOther"
            required={true}
            component={MyTextField}
            label="その他の設置場所"
            placeholder="その他の設置場所を入力してください。"
            className={classes.textField}
          />
        </Grid>
      ) : null
    const usingOther =
      this.props.using! && this.props.using!.includes('その他') ? (
        <Grid item={true} xs={12}>
          <Field
            id="usingOther"
            name="usingOther"
            required={true}
            component={MyTextField}
            label="その他の利用したもの"
            placeholder="その他の利用したものを入力してください。"
            className={classes.textField}
          />
        </Grid>
      ) : null
    return (
      <form onSubmit={handleSubmit}>
        <Typography variant="h6" gutterBottom={true}>
          捕獲時の状況を選択してください。
        </Typography>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <FormControl required={true}>
              <InputLabel htmlFor="timezone" className={classes.inputLabel}>
                天気
              </InputLabel>
              <Field
                id="weather"
                name="weather"
                required={true}
                component={SelectField}
                label="天気"
                className={classes.selectField}
              >
                <MenuItem>
                  <em>未選択</em>
                </MenuItem>
                {weather.map(val => {
                  return (
                    <MenuItem value={val.code} key={val.code}>
                      {val.name}
                    </MenuItem>
                  )
                })}
              </Field>
            </FormControl>
          </Grid>
          {weatherOther}
          <Grid item={true} xs={12}>
            <FormControl required={true}>
              <InputLabel htmlFor="catchType" className={classes.inputLabel}>
                捕獲の種類
              </InputLabel>
              <Field
                id="catchType"
                name="catchType"
                required={true}
                component={SelectField}
                label="捕獲の種類"
                className={classes.selectField}
              >
                <MenuItem>
                  <em>未選択</em>
                </MenuItem>
                {catchType.map(val => {
                  return (
                    <MenuItem value={val.code} key={val.code}>
                      {val.name}
                    </MenuItem>
                  )
                })}
              </Field>
            </FormControl>
          </Grid>
          {isNatural ? (
            <React.Fragment>
              <Grid item={true} xs={12}>
                <FormControl required={true}>
                  <InputLabel htmlFor="hiveType" className={classes.inputLabel}>
                    巣箱のタイプ
                  </InputLabel>
                  <Field
                    id="hiveType"
                    name="hiveType"
                    required={true}
                    component={SelectField}
                    label="巣箱のタイプ"
                    className={classes.selectField}
                  >
                    <MenuItem>
                      <em>未選択</em>
                    </MenuItem>
                    {hiveType.map(val => {
                      return (
                        <MenuItem value={val.code} key={val.code}>
                          {val.name}
                        </MenuItem>
                      )
                    })}
                  </Field>
                </FormControl>
              </Grid>
              {hiveTypeOther}
              <Grid item={true} xs={12}>
                <FormControl required={true}>
                  <InputLabel
                    htmlFor="hiveLocation"
                    className={classes.inputLabel}
                  >
                    設置場所
                  </InputLabel>
                  <Field
                    id="hiveLocation"
                    name="hiveLocation"
                    required={true}
                    component={SelectField}
                    label="設置場所"
                    className={classes.selectField}
                  >
                    <MenuItem>
                      <em>未選択</em>
                    </MenuItem>
                    {hiveLocation.map(val => {
                      return (
                        <MenuItem value={val.code} key={val.code}>
                          {val.name}
                        </MenuItem>
                      )
                    })}
                  </Field>
                </FormControl>
              </Grid>
              {hiveLocationOther}
              <Grid item={true} xs={12}>
                <FormControl required={true}>
                  <InputLabel htmlFor="using" className={classes.inputLabel}>
                    利用したもの
                  </InputLabel>
                  <Field
                    id="using"
                    name="using"
                    required={true}
                    component={MultiSelect}
                    label="利用したもの"
                    className={classes.selectField}
                    type="select-multiple"
                  >
                    {using.map(val => {
                      return (
                        <MenuItem value={val.name} key={val.code}>
                          <Checkbox
                            checked={
                              this.props.using! &&
                              this.props.using!.includes(val.name)
                                ? true
                                : false
                            }
                            value={val.code}
                          />
                          <ListItemText primary={val.name} />
                        </MenuItem>
                      )
                    })}
                  </Field>
                </FormControl>
              </Grid>
              {usingOther}
            </React.Fragment>
          ) : null}
        </Grid>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} className={classes.buttonContainer}>
            <Button
              color="secondary"
              variant="outlined"
              type="button"
              onClick={this.props.prevStep}
            >
              戻る
            </Button>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              disabled={myPristine || invalid}
            >
              次へ
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default withStyles(styles)(
  reduxForm<{}, IProps>({
    validate,
    form: 'report',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
  })(SecondStep),
)
