import ReportDialog from '../../components/dialogs/reportDialog'
import { closeDialog, postReport } from '../../actions/reportForm'
import { connect } from 'react-redux'
import { IRootState } from '../../reducers/rootReducer'
import { reset } from 'redux-form'

const mapStateToProps = (state: IRootState) => {
  return {
    isOpen: state.reportForm.isOpen,
    isSuccess: state.reportForm.isSuccess,
    isError: state.reportForm.isError,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeDialog: () => {
      dispatch(closeDialog())
    },
    postReport: (values: any) => {
      dispatch(postReport(values))
    },
    resetForm: () => {
      dispatch(reset('report'))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportDialog)
