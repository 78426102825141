import React, { Component } from 'react'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
} from '@material-ui/core'
import deepOrange from '@material-ui/core/colors/deepOrange'
import orange from '@material-ui/core/colors/orange'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './containers/homeContainer'
import MobileAppLp from './components/mobileAppLp'
import { isMobile, isIE } from 'react-device-detect'
import HowToUse from './components/static/howToUse'
import WarningIE from './components/warningIE'
import ReactGA from 'react-ga'
import AppHowToUse from './components/static/appHowToUse'
import AppLicense from './components/static/appLicense'
import AppPrivacy from './components/static/appPrivacy'
import Feedback from './components/static/feedback'

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || '')
ReactGA.set({ page: window.location.pathname })
ReactGA.pageview(window.location.pathname)

interface IState {}
interface IStyles extends WithStyles<typeof styles> {}
interface IAppProps {}
type IProps = IAppProps & IStyles

const theme = createMuiTheme({
  palette: {
    primary: deepOrange,
    secondary: orange,
  },
  typography: {
    h6: {
      fontSize: 16,
    },
  },
})
const styles = (theme: Theme) => createStyles({})

class App extends Component<IProps, IState> {
  renderContent = () => {
    if (isIE) {
      return <WarningIE />
    }

    return (
      <BrowserRouter basename="/">
        <React.Fragment>
          <Switch>
            <Route
              exact={true}
              path="/app/how_to_use"
              component={AppHowToUse}
            />
            <Route
              exact={true}
              path="/ja/app/how_to_use"
              component={AppHowToUse}
            />
            <Route exact={true} path="/app/license" component={AppLicense} />
            <Route exact={true} path="/app/privacy" component={AppPrivacy} />
            <Route exact={true} path="/ja/app/license" component={AppLicense} />
            <Route exact={true} path="/ja/app/privacy" component={AppPrivacy} />

            <Route exact={true} path="/how-to-use" component={HowToUse} />
            <Route exact={true} path="/feedback" component={Feedback} />

            <Route component={isMobile ? MobileAppLp : Home} />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    )
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {this.renderContent()}
      </MuiThemeProvider>
    )
  }
}

export default withStyles(styles)(App)
