import React, { Component } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Link,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import dayjs from "dayjs";

interface IState {
  values: {
    month: number;
  };
}
interface IStyles extends WithStyles<typeof styles> {}
interface IHeaderProps {
  // redux
  year: number;
  openDialog: () => void;
  updateMapLocations: (start: string, end: string) => void;
  setHeadConditionYear: (year: number) => void;

  isMapPage: boolean;
}
type IProps = IHeaderProps & IStyles;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    header: {
      backgroundColor: "white",
      color: "rgba(0, 0, 0, 0.87)",
    },
    titleYear: {
      fontSize: "16px",
    },
    button: {
      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        margin: "4px",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    mailSubscribeBtn: { textDecoration: "none" },
  });

const MIN_YEAR = 2017;
const MonthCondition = [
  { code: 0, value: "全期間" },
  { code: 2, value: "2月" },
  { code: 3, value: "3月" },
  { code: 4, value: "4月" },
  { code: 5, value: "5月" },
  { code: 6, value: "6月" },
  { code: 7, value: "7月" },
  { code: 8, value: "8月" },
];

class Header extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      values: {
        month: 0,
      },
    };
  }
  handleClick = (event: React.SyntheticEvent<{}, Event>) => {
    this.props.openDialog();
  };
  handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    if (event.target.name === "month") {
      this.setState({
        values: {
          month: event.target.value as number,
        },
      });
    } else {
      this.props.setHeadConditionYear(event.target.value as number);
    }
    this.updateMapLocations(
      event.target.name as string,
      event.target.value as number
    );
  };
  updateMapLocations(name: string, value: number) {
    let year = dayjs().year();
    let month = 0;
    let start = "";
    let end = "";
    if (name === "year") {
      year = value;
      month = this.state.values.month;
    } else {
      year = this.props.year;
      month = value;
    }
    if (month === 0) {
      start = `${year}-01-01`;
      end = `${year}-12-31`;
    } else {
      start = dayjs(`${year}-${month}-1`).format("YYYY-MM-DD");
      end = dayjs(`${year}-${month}-1`)
        .endOf("month")
        .format("YYYY-MM-DD");
    }
    this.props.updateMapLocations(start, end);
  }

  render() {
    const { classes, isMapPage } = this.props;
    const thisYear = Number(dayjs().year());
    const yearMenuItems = [];
    for (let y = thisYear; MIN_YEAR <= y; y -= 1) {
      yearMenuItems.push(
        <MenuItem value={y} key={y}>
          {y}年
        </MenuItem>
      );
    }
    const maxYm = `${thisYear}${dayjs().month() + 1}`;
    const monthMenuItems = MonthCondition.map((item) => {
      const itemYm = `${this.props.year}${item.code}`;
      return itemYm <= maxYm ? (
        <MenuItem value={item.code} key={item.code}>
          {item.value}
        </MenuItem>
      ) : null;
    });

    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.header}>
          <Toolbar>
            <Typography variant="h5" color="inherit" className={classes.grow}>
              <Link color="inherit" href="/">
                分蜂マップ&nbsp;
                <span className={classes.titleYear}>{this.props.year}</span>
              </Link>
            </Typography>
            <React.Fragment>
              {isMapPage && (
                <React.Fragment>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="year" />
                    <Select
                      value={this.props.year}
                      onChange={this.handleChange}
                      inputProps={{
                        name: "year",
                        id: "year",
                      }}
                    >
                      {yearMenuItems}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="month" />
                    <Select
                      value={this.state.values.month}
                      onChange={this.handleChange}
                      inputProps={{
                        name: "month",
                        id: "month",
                      }}
                    >
                      {monthMenuItems}
                    </Select>
                  </FormControl>
                </React.Fragment>
              )}
              <Button
                href="/how-to-use"
                variant="text"
                color="primary"
                className={classes.button}
              >
                使い方
              </Button>
              <Button
                onClick={() => {
                  window.location.href = "https://mailchi.mp/beekeep/swarm-map";
                }}
                variant="text"
                color="primary"
                className={classes.button}
              >
                メルマガに登録
              </Button>
              {isMapPage && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleClick}
                >
                  分蜂報告を投稿する
                </Button>
              )}
            </React.Fragment>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(Header);
