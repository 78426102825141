import React, { Component } from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import {
  Theme,
  createStyles,
  Typography,
  Button,
  Grid,
} from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/styles'
import MyTextField from './textField'
import validate, { confirmAnswer } from './validate'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 300,
      width: '60%',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    label: {
      margin: theme.spacing(1),
    },
  })

interface IState {}
interface IStyles extends WithStyles<typeof styles> {}
interface IStepProps {
  prevStep: () => void
  question: string
  answer: string
  isEdit?: boolean
}
type IProps = IStepProps & IStyles

class FourthStep extends Component<
  IProps & InjectedFormProps<{}, IProps>,
  IState
> {
  render() {
    const { handleSubmit, pristine, invalid, submitting, classes } = this.props
    return (
      <form onSubmit={handleSubmit}>
        {!this.props.isEdit ? (
          <Typography variant="h6" gutterBottom={true}>
            合言葉の設定
            <br />
            設定すると、編集や削除を後から行うことができます。
          </Typography>
        ) : null}
        <Grid container={true} spacing={1}>
          {!this.props.isEdit ? (
            <Grid item={true} xs={12}>
              <Field
                id="editKey"
                name="editKey"
                required={false}
                component={MyTextField}
                label="合言葉"
                placeholder="卒業した小学校の名前は？"
                className={classes.textField}
              />
            </Grid>
          ) : null}
          <Grid item={true} xs={12}>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.label}
            >
              人間による操作であるかどうかを確認しています。
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <label className={classes.label}>{this.props.question}</label>
            <Field
              id="answer"
              name="answer"
              required={true}
              component={MyTextField}
              label="質問の答え"
              placeholder="答えを入力"
              className={classes.textField}
              validate={[
                (value: string, values: any, props: any) =>
                  confirmAnswer(value)(props.answer),
              ]}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              variant="body1"
              gutterBottom={true}
              className={classes.label}
            >
              以上で、入力は完了です。投稿を修正したい方は
              <br />
              下記戻るボタンから修正が可能です。
            </Typography>
          </Grid>
        </Grid>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} className={classes.buttonContainer}>
            <Button
              color="secondary"
              variant="outlined"
              type="button"
              onClick={this.props.prevStep}
            >
              戻る
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={!submitting && (pristine || invalid)}
            >
              投稿
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default withStyles(styles)(
  reduxForm<{}, IProps>({
    validate,
    form: 'report',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(FourthStep),
)
