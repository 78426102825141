import React, { Component } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  FormControlLabel,
  Switch,
  Grid,
  CircularProgress,
  Link,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ReportListItem from "../containers/reportListItemContainer";
import EditConfirmDialg from "../containers/dialogs/editConfirmContainer";
import DeleteDialog from "../containers/dialogs/deleteDialogContainer";
import {
  IReportDetail,
  IReportDetailsFilter,
  IReportLocationsFilter,
} from "../types";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import ReportListAdItem from "./reportListAdItem";

interface IState {}
interface IStyles extends WithStyles<typeof styles> {}
interface IReportListProps {
  reports: IReportDetail[];
  checkedInBounds: boolean;
  filter: IReportDetailsFilter;
  total: number;
  isRequesting: boolean;
  locationsFilter: IReportLocationsFilter;
  setCheckedInBounds: (checked: boolean) => void;
  getReportList: () => void;
  setReportListNextPage: (nextPage: number) => void;
  updateReportLocation: (year: number) => void;
}
type IProps = IReportListProps & IStyles;

class ReportList extends Component<IProps, IState> {
  handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setCheckedInBounds(event.target.checked);
  };

  render() {
    const { classes } = this.props;
    const reports = this.props.reports;
    const nextPage = this.props.filter.nextPage;
    let notFoundMessage = null;
    if (this.props.total <= 0) {
      const thisyear = dayjs().year();
      const allThisyear =
        this.props.locationsFilter.periodType === "thisyear" ||
        (this.props.locationsFilter.start === `${thisyear}-01-01` &&
          this.props.locationsFilter.end === `${thisyear}-12-31`);
      notFoundMessage = allThisyear ? (
        <p>
          {thisyear}年はまだ報告がありません。{thisyear - 1}
          年の分蜂報告を閲覧するには、
          <Link
            component="button"
            // tslint:disable-next-line: jsx-no-lambda
            onClick={() => {
              this.props.updateReportLocation(thisyear - 1);
            }}
          >
            こちら。
          </Link>
          <br />
          メールで最新の分蜂報告や、分蜂予測を受け取ることができます。メルマガ登録は
          <Link
            href="https://mailchi.mp/beekeep/swarm-map"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら。
          </Link>
        </p>
      ) : (
        <p>見つかりませんでした。</p>
      );
    }

    return (
      <React.Fragment>
        <React.Fragment>
          {this.props.total > 0 ? (
            <React.Fragment>
              <div id="reportList" />
              <List
                id="scrollableList"
                className={classes.root}
                subheader={
                  <ListSubheader className={classes.subHeader} color="primary">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.props.checkedInBounds}
                          onChange={this.handleChange()}
                          value="checked"
                          color="primary"
                        />
                      }
                      label="地図内の投稿に絞り込む"
                      className={classes.label}
                    />
                  </ListSubheader>
                }
              >
                <InfiniteScroll
                  dataLength={reports.length}
                  next={() => {
                    this.props.getReportList();
                  }}
                  hasMore={nextPage !== -1}
                  loader={
                    <Grid
                      container={true}
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justify="center"
                      className={classes.root}
                    >
                      <CircularProgress />
                    </Grid>
                  }
                  scrollableTarget="scrollableList"
                  scrollThreshold={0.9}
                >
                  {reports.map((report, index) => {
                    return (
                      <>
                        {index % 10 === 2 && (
                          <ReportListAdItem listItemIndex={index} mode="mail" />
                        )}
                        {index % 10 === 7 && (
                          <ReportListAdItem
                            listItemIndex={index}
                            mode="youtube"
                          />
                        )}
                        <ReportListItem key={report.id} report={report} />
                      </>
                    );
                  })}
                </InfiniteScroll>
              </List>
            </React.Fragment>
          ) : (
            <div className={classes.notFound}>{notFoundMessage}</div>
          )}
        </React.Fragment>
        <EditConfirmDialg />
        <DeleteDialog />
      </React.Fragment>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: "rgba(0, 0, 0, 0.87)",
      height: "calc(100% - 64px)",
      overflowY: "auto",
    },
    subHeader: {
      backgroundColor: "#fff",
      paddingTop: "8px",
      textAlign: "left",
    },
    label: {
      color: "rgba(0, 0, 0, 0.87)",
    },
    pagination: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
    },
    notFound: {
      color: "rgba(0, 0, 0, 0.87)",
      padding: theme.spacing(1),
    },
  });

export default withStyles(styles)(ReportList);
