import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as actions from '../actions/clusterDialog'

export type ClusterDialogState = {
  readonly isOpen: boolean
}

const initialState: ClusterDialogState = {
  isOpen: false,
}

export default reducerWithInitialState(initialState)
  .case(actions.openDialog, (state: ClusterDialogState) => ({
    ...state,
    isOpen: true,
  }))
  .case(actions.closeDialog, (state: ClusterDialogState) => ({
    ...state,
    isOpen: false,
  }))

  .build()
