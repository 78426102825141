import React, { Component, ReactNode } from 'react'
import { WithStyles, Theme, createStyles, withStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import 'basscss/css/basscss.min.css'
// Owl-carouselをimport
import OwlCarousel, { Options } from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

interface IState {
  options: Options
  items: ReactNode[]
}
interface IStyles extends WithStyles<typeof styles> {}
interface IAppProps {}
type IProps = IAppProps & IStyles

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
  })

class MobileAppLp extends Component<IProps, IState> {
  public state: IState = {
    options: {
      loop: true,
      margin: 10,
      nav: false,
      autoplay: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 3,
          autoplay: false,
        },
      },
    },
    items: [
      <div className="item" key={1}>
        <img src="assets/img/app_android_1.png" alt="" />
      </div>,
      <div className="item" key={2}>
        <img src="assets/img/app_android_2.png" alt="" />
      </div>,
      <div className="item" key={3}>
        <img src="assets/img/app_android_3.png" alt="" />
      </div>,
    ],
  }
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <section className="jumbotron-container" id="home">
          <Box pt={{ xs: 2, sm: 10 }} ml={{ sm: 2 }}>
            <Container maxWidth="md">
              <Grid container={true}>
                <Grid item={true} sm={7}>
                  <Box
                    fontSize={14}
                    textAlign={{ xs: 'center', sm: 'left' }}
                    className="yellow-text"
                  >
                    分蜂マップはアプリでお使いください
                  </Box>

                  <Box
                    component="h1"
                    mb={3}
                    textAlign={{ xs: 'center', sm: 'left' }}
                  >
                    <img
                      className="col-11 sm-col-7 responsive-img"
                      alt="分蜂マップアプリ"
                      src="assets/img/logo.svg"
                    />
                  </Box>
                  <h2 className="left-align mb3 h3 white-text line-height-4">
                    日本各地のニホンミツバチの分蜂情報が 地図上でわかる
                    「分蜂マップ」がアプリになりました！
                    アプリならプッシュ通知で携帯に分蜂情報が届く！
                    <br />
                    <Box component="span" className="bold" bgcolor="primary">
                      機能はすべて
                      <Box
                        component="span"
                        mx={0.5}
                        fontSize={22}
                        className="yellow-text"
                      >
                        無料
                      </Box>
                      で使えます！
                    </Box>
                  </h2>
                  <div className="store-btn flex mt2">
                    <a
                      href="https://itunes.apple.com/jp/app/id1437053381?mt=8"
                      className=""
                    >
                      <img
                        className="responsive-img"
                        src="assets/img/app-store.png"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.syumatsuyoho.swarm"
                      className=""
                    >
                      <img
                        className="responsive-img"
                        src="assets/img/google-play.png"
                        alt=""
                      />
                    </a>
                  </div>
                </Grid>
                <Grid item={true} sm={5}>
                  <div className="jumbotron-device">
                    <img
                      className="responsive-img"
                      src="assets/img/jumbotron-device.png"
                      alt=""
                    />
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </section>
        <section className="screenshot-container mb2" id="screenshots">
          <div className="conatiner center">
            <div className="row">
              <div className="flex mt2 col-12 md-col-9 mx-auto">
                <OwlCarousel className="owl-theme" {...this.state.options}>
                  {this.state.items}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="store-btn flex justify-center mt2 p2 mb2 center">
            <a
              href="https://itunes.apple.com/jp/app/id1437053381?mt=8"
              className="m1 center deep-orange-text"
            >
              <img
                className="responsive-img"
                src="assets/img/app-store.png"
                alt=""
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.syumatsuyoho.swarm"
              className="m1 center deep-orange-text"
            >
              <img
                className="responsive-img"
                src="assets/img/google-play.png"
                alt=""
              />
            </a>
          </div>
        </section>
        <footer className="page-footer deep-orange lighten-1">
          <div className="footer-copyright">
            <div className="container">
              <div className="row">
                <div className="col s12 m6 white-text">
                  &copy; 京都ニホンミツバチ週末養蜂の会, All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </footer>
        <style jsx={true} global={true}>{`
          body {
            overflow-x: hidden;
          }
          h2 {
            font-weight: 400;
          }
          .white-text {
            color: #fff !important;
          }
          .yellow-text {
            color: #ffeb3b;
          }
          .jumbotron-container {
            background-image: url('./assets/img/jumbotron-bg.jpg');
            background-position: top;
            background-size: cover;
            background-attachment: fixed;
          }
          .jumbotron-container h1 {
            color: #fff;
            font-size: 2.142857142857143rem;
            line-height: 1.6em;
            font-weight: 100;
          }
          .jumbotron-container .store-btn {
            height: auto;
            margin: 0 auto;
          }
          .jumbotron-container .jumbotron-device {
            position: relative;
            margin: 20px 0 0;
            z-index: 1;
          }
          .screenshot-container {
            position: relative;
            -webkit-box-shadow: 0px -4px 5px -2px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0px -4px 5px -2px rgba(0, 0, 0, 0.25);
            box-shadow: 0px -4px 5px -2px rgba(0, 0, 0, 0.25);
            padding: 25px 0 0;
          }
          .screenshot-container h2,
          .screenshot-container p {
            text-align: center;
          }
          .screenshot-container h2 {
            color: #131313;
          }
          .screenshot-container #screenshot-carousel .item {
            margin: 10px;
          }
          .screenshot-container #screenshot-carousel .item img {
            display: block;
            width: 100%;
            height: auto;
            border-radius: 2px;
          }
          footer.page-footer {
            padding-top: 0;
            margin-top: 0;
          }
          img.responsive-img,
          video.responsive-video {
            max-width: 100%;
            height: auto;
          }
          footer {
            display: block;
          }
          page-footer {
            padding-top: 20px;
            color: #fff;
            background-color: #ee6e73;
          }
          .page-footer .footer-copyright {
            overflow: hidden;
            min-height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0px;
            color: rbga(255, 255, 255, 0.8);
            background-color: rgba(51, 51, 51, 0.08);
          }
          .deep-orange.lighten-1 {
            background-color: #ff7043;
          }
          .container {
            margin: 0 auto;
            max-width: 1280px;
            width: 90%;
          }
          .row {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
          }
          .row .col {
            float: left;
            box-sizing: border-box;
            padding: 0 0.75rem;
            min-height: 1px;
          }
          .row .col.s12 {
            width: 100%;
            margin-left: auto;
            left: auto;
            right: auto;
          }
          .row:after {
            content: '';
            display: table;
            clear: both;
          }
        `}</style>
      </div>
    )
  }
}

export default withStyles(styles)(MobileAppLp)
