import { IRootState } from '../reducers/rootReducer'
import { IReportDetail, IReportDetailsFilter } from '../types'
import actionCreatorFactory from 'typescript-fsa'
import { Dispatch } from 'redux'
import webAPI from '../apis/webAPI'
import { getReportDetailsError } from './reportDetails'

const PER_PAGE = 20

type GetState = () => IRootState
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
const actionCreator = actionCreatorFactory()

export const getReportListRequest = actionCreator('GET_REPORT_LIST_REQUEST')
export const getReportListSuccess = actionCreator<IReportDetail[]>(
  'GET_REPORT_LIST_SUCCESS',
)
export const getReportListError = actionCreator('GET_REPORT_LIST_ERROR')
export const setReportListFilter = actionCreator<IReportDetailsFilter>(
  'SET_REPORT_LIST_FILTER',
)
export const setReportListFilterIds = actionCreator<number[]>(
  'SET_REPORT_LIST_FILTER_IDS',
)
export const setReportListFilterDate = actionCreator<{
  start: string
  end: string
}>('SET_REPORT_LIST_FILTER_DATE')
export const setReportListNextPage = actionCreator<number>(
  'SET_REPORT_LIST_FILTER_NEXT_PAGE',
)
export const setCheckedInBounds = actionCreator<boolean>(
  'SET_CHECKED_IN_BOUNDS',
)
export const setReportListTotal = actionCreator<number>('SET_REPORT_LIST_TOTAL')

const createEndopointByFilter = (filter: IReportDetailsFilter): string => {
  let endpoint: string = ''
  if (filter.mode === 'ids' && filter.ids.length > 0) {
    const start = PER_PAGE * (filter.nextPage - 1)
    const end = start + PER_PAGE
    endpoint = `/reports/${filter.ids.slice(start, end).join(',')}.json`
  } else if (filter.mode === 'date') {
    endpoint = `/reports/details/${filter.range.start}/${filter.range.end}`
  }
  return endpoint
}

export const getReportList = (): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  getReportListImpl(dispatch, getState)
}

export const getReportListImpl = (dispatch: Dispatch, getState: GetState) => {
  const filter = getState().reportList.filter
  const isRequesting = getState().reportList.isRequesting

  if (isRequesting) return
  if (filter.nextPage === -1) return

  const endpoint = createEndopointByFilter(filter)
  if (endpoint === '') return

  dispatch(getReportListRequest())
  let params = {}
  if (filter.mode === 'date') {
    params = {
      page: filter.nextPage,
    }
  }
  webAPI
    .get(endpoint, { params })
    .then(res => {
      let nextPage
      if (filter.mode === 'date') {
        nextPage = res.data.last_page ? -1 : filter.nextPage + 1
      } else {
        nextPage =
          PER_PAGE * filter.nextPage >= filter.ids.length
            ? -1
            : filter.nextPage + 1
      }
      dispatch(setReportListNextPage(nextPage))

      const result: IReportDetail[] = res.data.items
      dispatch(getReportListSuccess(result))
    })
    .catch(error => {
      console.log(error)
      dispatch(getReportDetailsError())
    })
}

export const getReportListInBounds = (): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  getReportListInBoundsImpl(dispatch, getState)
}

export const getReportListInBoundsImpl = (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const locations = getState().reportLocations.locations
  const bounds = getState().reportLocations.bounds
  if (bounds === null) return
  if (locations.locations.length <= 0) {
    dispatch(setReportListFilterIds([]))
    dispatch(setReportListTotal(0))
    dispatch(getReportListSuccess([]))
    return
  }

  const checkedInBounds = getState().reportList.checkedInBounds
  let ids = []
  if (checkedInBounds) {
    ids = locations.locations
      .filter(location => {
        const lat = location.locations.latitude
        const lng = location.locations.longitude
        if (
          lat <= bounds.ne.lat &&
          lng <= bounds.ne.lng &&
          lat >= bounds.sw.lat &&
          lng >= bounds.sw.lng
        ) {
          return true
        }
        return false
      })
      .map(location => {
        return location.id
      })
  } else {
    ids = locations.locations.map(location => {
      return location.id
    })
  }
  dispatch(setReportListFilterIds(ids))
  dispatch(setReportListTotal(ids.length))
  getReportListImpl(dispatch, getState)
}
