import supercluster from 'supercluster'
import { ILatLngBounds, IReportItemLocation } from '../types'
import { BBox } from 'geojson'

export const getZoomLevelByLngDelta = (longitudeDelta: number) => {
  const angle = longitudeDelta
  return Math.round(Math.log(360 / angle) / Math.LN2)
}

const getClusterRadius = (zoomLevel: number) => {
  let radius: number = 100
  if (zoomLevel === 5) {
    radius = 45
  } else if (zoomLevel === 6) {
    radius = 35
  } else if (zoomLevel === 7) {
    radius = 20
  } else if (zoomLevel === 8) {
    radius = 10
  }
  return radius
}

export const isInside = (
  mapBounds: ILatLngBounds,
  clusterBBox: BBox,
): boolean => {
  const mapSWLng = mapBounds.sw.lng
  const mapSWLat = mapBounds.sw.lat

  if (clusterBBox[0] > mapSWLng || mapSWLng > clusterBBox[2]) {
    return false
  }

  if (clusterBBox[1] > mapSWLat || mapSWLat > clusterBBox[3]) {
    return false
  }

  const mapNELng = mapBounds.ne.lng
  const mapNELat = mapBounds.ne.lat

  if (clusterBBox[0] > mapNELng || mapNELng > clusterBBox[2]) {
    return false
  }

  if (clusterBBox[1] > mapNELat || mapNELat > clusterBBox[3]) {
    return false
  }

  return true
}

export const getClusterArea = (bounds: ILatLngBounds): BBox => {
  const longitudeDelta = bounds.ne.lng - bounds.sw.lng
  const latitudeDelta = bounds.ne.lat - bounds.sw.lat
  const zoomLevel: number = getZoomLevelByLngDelta(longitudeDelta)
  if (zoomLevel < 6) {
    return [120, 20, 150, 50]
  }
  const padding = 0.1
  return [
    bounds.sw.lng - longitudeDelta * padding,
    bounds.sw.lat - latitudeDelta * padding,
    bounds.ne.lng + longitudeDelta * padding,
    bounds.ne.lat + latitudeDelta * padding,
  ]
}

export const getCluster = (
  points: IReportItemLocation[],
  bounds: ILatLngBounds,
) => {
  const longitudeDelta = bounds.ne.lng - bounds.sw.lng
  const zoomLevel = getZoomLevelByLngDelta(longitudeDelta)

  const cluster = new supercluster({
    radius: getClusterRadius(zoomLevel),
    maxZoom: 12,
  })
  let markers
  try {
    let allCoords: any
    allCoords = points.map(c => ({
      type: 'Feature',
      geometry: {
        coordinates: [c.locations.longitude, c.locations.latitude],
      },
      properties: { point_count: 1, item: c.id },
    }))

    cluster.load(allCoords)
    const clusterArea = getClusterArea(bounds)
    markers = cluster.getClusters(clusterArea, zoomLevel)
  } catch (e) {
    console.log(e)
  }

  return {
    markers,
    cluster,
  }
}
