import React, { Component } from 'react'
import { withGoogleMap, GoogleMap, withScriptjs } from 'react-google-maps'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import { ILatLngBounds, ILatLng } from '../types'
import { BBox } from 'geojson'
import { isInside } from './mapUtil'
import ClusterMarker from '../containers/myClusterMarkerContainer'

interface IState {
  map: GoogleMap | null
  isMapFirstLoad: boolean
}
interface IStyles extends WithStyles<typeof styles> {}
interface IMyMapProps {
  setBounds: (bounds: ILatLngBounds) => void
  calcClusters: () => void
  setCenter: (center: ILatLng) => void
  setZoom: (zoom: number) => void
  getReportListInBounds: () => void
  mapCenter: ILatLng
  mapZoom: number
  clusterAreaRendered: BBox
  checkedInBounds: boolean
}
type IProps = IMyMapProps & IStyles

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
    },
  })

class MyMap extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      map: null as (GoogleMap | null),
      isMapFirstLoad: true,
    }
  }

  shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    if (this.state.map && nextState.map) {
      const thisCenter = this.state.map.getCenter()
      const thisZoom = this.state.map.getZoom()
      const nextCenter = nextState.map.getCenter()
      const nextZoom = nextState.map.getZoom()
      if (
        thisCenter.lat() === nextCenter.lat() &&
        thisCenter.lng() === nextCenter.lng() &&
        thisZoom === nextZoom
      ) {
        return false
      }
      return true
    }
    return false
  }

  onMapMounted = (mapRef: GoogleMap) => {
    this.setState({
      ...this.state,
      map: mapRef,
    })
  }

  onBoundsChanged = () => {
    if (this.state.isMapFirstLoad) {
      this.setState({
        ...this.state,
        isMapFirstLoad: false,
      })
      if (this.state.map) {
        this.recalcCluster(false)
      }
    }
  }

  onZoomChanged = () => {
    if (this.state.map) {
      const zoom = this.state.map.getZoom()
      this.props.setZoom(zoom)
      this.recalcCluster(true)
    }
  }

  onDragEnd = () => {
    if (this.state.map) {
      this.recalcCluster(false)
    }
  }

  recalcCluster = (isZoomChanged: boolean) => {
    if (this.state.map) {
      const center = this.state.map.getCenter()
      this.setCenter(center)
      const bounds = (this.state.map as GoogleMap).getBounds()
      const newBounds = this.getBounds(bounds)
      this.props.setBounds(newBounds)
      if (
        isZoomChanged ||
        !isInside(newBounds, this.props.clusterAreaRendered)
      ) {
        this.props.calcClusters()
      }
      if (this.props.checkedInBounds) {
        this.props.getReportListInBounds()
      }
    }
  }

  setCenter = (center: any) => {
    if (center.lat() && center.lng()) {
      const newCenter = {
        lat: center.lat(),
        lng: center.lng(),
      }
      this.props.setCenter(newCenter)
    }
  }

  getBounds = (mapBounds: any) => {
    const neLatLng = mapBounds.getNorthEast()
    const ne: ILatLng = {
      lat: neLatLng.lat(),
      lng: neLatLng.lng(),
    }
    const swLatLng = mapBounds.getSouthWest()
    const sw: ILatLng = {
      lat: swLatLng.lat(),
      lng: swLatLng.lng(),
    }
    return {
      ne,
      sw,
    }
  }

  render() {
    const OPTIONS = {
      minZoom: 6,
      maxZoom: 12,
      streetViewControl: false,
      mapTypeControl: false,
    }

    const GoogleMapComponent = withScriptjs(
      withGoogleMap(() => {
        return (
          <GoogleMap
            options={OPTIONS}
            ref={this.onMapMounted}
            defaultCenter={this.props.mapCenter}
            defaultZoom={this.props.mapZoom}
            onBoundsChanged={this.onBoundsChanged}
            onZoomChanged={this.onZoomChanged}
            onDragEnd={this.onDragEnd}
          >
            <ClusterMarker />
          </GoogleMap>
        )
      }),
    )

    return (
      <React.Fragment>
        <GoogleMapComponent
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLEMAP_API_KEY}`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div style={{ height: `calc(100vh - 64px)`, width: '100%' }} />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(MyMap)
