import { IRootState } from '../reducers/rootReducer'
import { connect } from 'react-redux'
import MyClusterMarker from '../components/myClusterMarker'
import {
  setReportDetailsFilterIds,
  getReportDetails,
} from '../actions/reportDetails'
import { openDialog } from '../actions/clusterDialog'

const mapStateToProps = (state: IRootState) => {
  return {
    clusters: state.reportLocations.clusters,
    clusterMarkerIds: state.reportLocations.clusterMarkerIds,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onClusterClick: (ids: number[]) => {
      dispatch(setReportDetailsFilterIds(ids))
      dispatch(getReportDetails())
      dispatch(openDialog())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyClusterMarker)
