import actionCreatorFactory from 'typescript-fsa'
import { IRootState } from '../reducers/rootReducer'
import { Dispatch, AnyAction } from 'redux'
import webAPI from '../apis/webAPI'
import { IFormOption, IReportItemLocation } from '../types'
import {
  setNewLocation,
  setUpdatedLocation,
  createClusters,
} from './reportLocations'
import { getReportListInBoundsImpl } from './reportList'

type GetState = () => IRootState
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
const actionCreator = actionCreatorFactory()

export const openDialog = actionCreator('OPEN_REPORT_DIALOG')
export const closeDialog = actionCreator('CLOSE_REPORT_DIALOG')
export const openEditDialog = actionCreator<string>('OPEN_EDIT_DIALOG')
export const closeEditDialog = actionCreator('CLOSE_EDIT_DIALOG')
export const setTargetReport = actionCreator<any>('SET_TARGET_REPORT')

export const postReportRequest = actionCreator('POST_REPORT_REQUEST')
export const postReportSuccess = actionCreator('POST_REPORT_SUCCESS')
export const postReportError = actionCreator('POST_REPORT_ERROR')

export const setCheckedImageDelete = actionCreator<boolean>(
  'SET_CHECKED_IMAGE_DELETE',
)

export const postReport = (values: any): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  dispatch(postReportRequest())

  const params = getParams(values, getState)
  params.append('report[edit_key]', values.editKey ? values.editKey : '')

  webAPI
    .post('/reports', params, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then(res => {
      const location: IReportItemLocation = {
        id: res.data.locations.id,
        locations: {
          latitude: res.data.locations.pos[0],
          longitude: res.data.locations.pos[1],
        },
      }
      dispatch(setNewLocation(location))
      dispatch(createClusters() as AnyAction)
      getReportListInBoundsImpl(dispatch, getState)
      dispatch(postReportSuccess())
    })
    .catch(error => {
      console.log(error)
      dispatch(postReportError())
    })
}

export const updateReport = (values: any): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  dispatch(postReportRequest())

  const targetReport = getState().reportForm.targetReport

  dispatch(postReportSuccess())
  const params = getParams(values, getState)
  params.append(
    'report[edit_key]',
    targetReport!.edit_key ? targetReport!.edit_key : '',
  )
  params.append(
    'delete_image',
    getState().reportForm.checkedImageDelete ? 'true' : 'false',
  )

  webAPI
    .put(`/reports/${targetReport!.id}`, params, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then(res => {
      const location: IReportItemLocation = {
        id: res.data.locations.id,
        locations: {
          latitude: res.data.locations.pos[0],
          longitude: res.data.locations.pos[1],
        },
      }
      dispatch(setUpdatedLocation(location))
      dispatch(createClusters() as AnyAction)
      getReportListInBoundsImpl(dispatch, getState)
      dispatch(postReportSuccess())
    })
    .catch(error => {
      console.log(error)
      dispatch(postReportError())
    })
}

const getParams = (values: any, getState: GetState): FormData => {
  const params = new FormData()
  if (values.image) {
    params.append('images[]', values.image)
  }
  params.append('report[nickname]', values.nickname ? values.nickname : '')
  params.append('report[catch_date]', values.catchDate ? values.catchDate : '')
  params.append('report[time_zone]', values.timezone ? values.timezone : '')
  params.append('report[prefectural_id]', values.pref ? values.pref : '')
  params.append('report[area_id]', values.city ? values.city : '')
  params.append('report[weather]', values.weather ? values.weather : '')
  params.append(
    'report[weather_other]',
    values.weatherOther ? values.weatherOther : '',
  )
  const catchType = values.catchType ? values.catchType : ''
  params.append('report[catch_type]', catchType)
  if (catchType === 'cat01') {
    params.append('report[hive_type]', values.hiveType ? values.hiveType : '')
    params.append(
      'report[hive_type_other]',
      values.hiveTypeOther ? values.hiveTypeOther : '',
    )
    params.append(
      'report[hive_location]',
      values.hiveLocation ? values.hiveLocation : '',
    )
    params.append(
      'report[hive_location_other]',
      values.hiveLocationOther ? values.hiveLocationOther : '',
    )
    if (values.using) {
      const usingOption = getState().formOptions.options!.using
      const using = getUsingIds(values.using, usingOption)
      for (let i = 0; i < using.length; i += 1) {
        if (using[i] === 'other') {
          params.append('report[using][]', values.usingOther)
        } else {
          params.append('report[using][]', using[i])
        }
      }
    }
    params.append(
      'report[using_other]',
      values.usingOther ? values.usingOther : '',
    )
  } else {
    params.append('report[hive_type]', '')
    params.append('report[hive_type_other]', '')
    params.append('report[hive_location]', '')
    params.append('report[hive_location_other]', '')
    params.append('report[using][]', '')
    params.append('report[using_other]', '')
  }
  params.append(
    'report[description]',
    values.description ? values.description : '',
  )

  const question = getState().spamCheck.question
  params.append('report[question]', question ? question : '')
  params.append('report[answer]', values.answer ? values.answer : '')
  return params
}

const getUsingIds = (usingNames: string[], usingOption: IFormOption[]) => {
  return usingNames.map(name => {
    const res = usingOption.filter(item => {
      if (item.name === name) {
        return true
      }
      return false
    })
    return res[0].code
  })
}
