import React, { Component } from 'react'
import {
  Theme,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  TextField,
  Button,
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles, WithStyles } from '@material-ui/styles'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 18,
    },
    titleContainer: {
      paddingBottom: 0,
    },
    title: {
      fontSize: 24,
    },
    content: {
      overflowY: 'unset',
      minWidth: 430,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margi: theme.spacing(1),
      position: 'relative',
    },
  })

interface IStyles extends WithStyles<typeof styles> {}
interface IState {
  editKey: string
}
interface IEditConfirmProps {
  readonly isOpen: boolean
  readonly isSuccess: boolean | null
  readonly isError: boolean | null
  readonly isRequesting: boolean
  checkEditKey: (editKey: string) => void
  closeEditConfirmDialog: () => void
}
type IProps = IEditConfirmProps & IStyles

class EditConfirmDialog extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      editKey: '',
    }
  }
  handleSubmit = () => {
    this.props.checkEditKey(this.state.editKey)
  }
  handleClose = () => {
    this.props.closeEditConfirmDialog()
  }
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      editKey: e.target.value,
    })
  }
  render() {
    const { classes } = this.props
    let content = '投稿を編集するには、合言葉を入力してください。'
    if (this.props.isError) {
      content = '合言葉が違います。'
    }
    return (
      <Dialog
        open={this.props.isOpen}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        maxWidth="xs"
        aria-labelledby="confirm-dialog-title"
        className={classes.root}
      >
        <DialogTitle
          id="confirm-dialog-title"
          className={classes.titleContainer}
        >
          <span className={classes.title}>投稿を編集する</span>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12}>
              <Typography variant="h6" gutterBottom={true}>
                {content}
              </Typography>
            </Grid>
            {!this.props.isSuccess ? (
              <Grid item={true} xs={12}>
                <TextField
                  id="editKey"
                  name="editKey"
                  label="合言葉"
                  type="input"
                  required={true}
                  onChange={this.handleChange}
                />
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} variant="contained">
            キャンセル
          </Button>
          {!this.props.isSuccess ? (
            <div className={classes.wrapper}>
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
                disabled={
                  this.props.isRequesting || this.state.editKey.length <= 0
                }
              >
                編集する
              </Button>
              {this.props.isRequesting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          ) : null}
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EditConfirmDialog)
