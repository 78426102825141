import { connect } from 'react-redux'
import { IRootState } from '../reducers/rootReducer'
import ReportList from '../components/reportList'
import {
  setCheckedInBounds,
  getReportListInBounds,
  getReportList,
  setReportListNextPage,
} from '../actions/reportList'
import { setHeadConditionYear } from '../actions/headCondition'
import {
  setReportLocationsFilter,
  getReportLocations,
} from '../actions/reportLocations'

const mapStateToProps = (state: IRootState) => {
  return {
    reports: state.reportList.reports,
    checkedInBounds: state.reportList.checkedInBounds,
    filter: state.reportList.filter,
    total: state.reportList.total,
    isRequesting: state.reportList.isRequesting,
    locationsFilter: state.reportLocations.filter,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCheckedInBounds: (checked: boolean) => {
      dispatch(setCheckedInBounds(checked))
      dispatch(getReportListInBounds())
    },
    getReportList: () => {
      dispatch(getReportList())
    },
    setReportListNextPage: (nextPage: number) => {
      dispatch(setReportListNextPage(nextPage))
    },
    updateReportLocation: (year: number) => {
      const start = `${year}-01-01`
      const end = `${year}-12-31`
      dispatch(setHeadConditionYear(year))
      dispatch(setReportLocationsFilter({ start, end, periodType: 'date' }))
      dispatch(getReportLocations())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportList)
