import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as actions from '../actions/spamCheck'

export type SpamCheckState = {
  question: string
  answer: string
  isRequesting: boolean
  isError: boolean | null
}

const initialState: SpamCheckState = {
  question: '',
  answer: '',
  isRequesting: false,
  isError: null,
}

export default reducerWithInitialState(initialState)
  .case(actions.getSpamCheckRequest, (state: SpamCheckState) => ({
    ...state,
    isRequesting: true,
    isError: null,
  }))
  .case(
    actions.getSpamCheckQuestionSuccess,
    (state: SpamCheckState, question: string) => ({
      ...state,
      question,
      isRequesting: false,
      isError: false,
    }),
  )
  .case(
    actions.getSpamCheckAnswerSuccess,
    (state: SpamCheckState, answer: string) => ({
      ...state,
      answer,
      isRequesting: false,
      isError: false,
    }),
  )
  .case(actions.getSpamCheckError, (state: SpamCheckState) => ({
    ...state,
    isRequesting: false,
    isError: true,
  }))
  .build()
