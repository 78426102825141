import React, { Component } from 'react'
import StaticPage from './staticPage'

class HowToUse extends Component {
  render() {
    return <StaticPage markdownFileName="howToUse" hasHeader={true} />
  }
}

export default HowToUse
