import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { IFormOptions } from '../types'
import * as actions from '../actions/formOptions'

export type FormOptionsState = {
  options: IFormOptions | null
  isRequesting: boolean
  isError: boolean | null
}

const initialState: FormOptionsState = {
  options: null,
  isRequesting: false,
  isError: null,
}

export default reducerWithInitialState(initialState)
  .case(actions.getFormOptionsRequest, (state: FormOptionsState) => ({
    ...state,
    isRequesting: true,
    isError: null,
  }))
  .case(
    actions.getFormOptionsSuccess,
    (state: FormOptionsState, options: IFormOptions) => ({
      ...state,
      options,
      isRequesting: false,
      isError: false,
    }),
  )
  .case(actions.getFormOptionsError, (state: FormOptionsState) => ({
    ...state,
    isRequesting: false,
    isError: true,
  }))
  .build()
