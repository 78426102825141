import { connect } from 'react-redux'
import DeleteDialog from '../../components/dialogs/deleteDialog'
import { deleteReport, closeDeleteDialog } from '../../actions/deleteDialog'
import { IRootState } from '../../reducers/rootReducer'

const mapStateToProps = (state: IRootState) => {
  return {
    isOpen: state.deleteDialog.isOpen,
    isSuccess: state.deleteDialog.isSuccess,
    isError: state.deleteDialog.isError,
    isRequesting: state.deleteDialog.isRequesting,
    targetPostId: state.deleteDialog.targetReportId,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteReport: (editKey: string) => {
      dispatch(deleteReport(editKey))
    },
    closeDeleteDialog: () => {
      dispatch(closeDeleteDialog())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteDialog)
