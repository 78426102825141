import { getReportLocations } from '../actions/reportLocations'
import Home from '../components/home'
import { connect } from 'react-redux'
import { IRootState } from '../reducers/rootReducer'
import { getFormOptions } from '../actions/formOptions'
import { getPrefs } from '../actions/prefs'
import { getSpamCheckQuestion } from '../actions/spamCheck'

const mapStateToProps = (state: IRootState) => {
  return {
    formIsOpen: state.reportForm.isOpen,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getReportLocations: () => {
      dispatch(getReportLocations())
    },
    getFormOptions: () => {
      dispatch(getFormOptions())
    },
    getPrefs: () => {
      dispatch(getPrefs())
    },
    getSpamCheckQuestion: () => {
      dispatch(getSpamCheckQuestion())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home)
