import SecondStep from '../../components/forms/secondStep'
import { connect } from 'react-redux'
import { IRootState } from '../../reducers/rootReducer'
import { formValueSelector } from 'redux-form'

const selector = formValueSelector('report')
const mapStateToProps = (state: IRootState) => {
  return {
    options: state.formOptions.options,
    weather: selector(state, 'weather'),
    catchType: selector(state, 'catchType'),
    hiveType: selector(state, 'hiveType'),
    hiveLocation: selector(state, 'hiveLocation'),
    using: selector(state, 'using'),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecondStep)
