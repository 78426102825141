import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as actions from '../actions/headCondition'
import dayjs from 'dayjs'

export type HeadConditionState = {
  year: number
}

const initialState: HeadConditionState = {
  year: dayjs().year(),
}

export default reducerWithInitialState(initialState)
  .case(
    actions.setHeadConditionYear,
    (state: HeadConditionState, year: number) => ({
      ...state,
      year,
    }),
  )
  .build()
