export type YouTubeAd = {
  id: string; // youtube video id
  title: string; // 捕獲のコツ
  duration: string; // 9:49
};

export const adYouTubeData: YouTubeAd[] = [
  {
    id: "LzJx1ILkBzg",
    title: "ニホンミツバチ養蜂歴20年！達人のハチミツの採り方",
    duration: "17:26",
  },
  {
    id: "NQ8fZTe4xPQ",
    title: "大量発生！ニホンミツバチのお引っ越し解説します。",
    duration: "3:03",
  },
  {
    id: "YH4LcM-JaE4",
    title: "捕獲の必須アイテム！これを使えば楽々捕獲♪【集合板解説】",
    duration: "2:44",
  },
  {
    id: "2VXpk9zGyEY",
    title: "ニホンミツバチの待ち箱に蜜蝋を塗ろう！",
    duration: "6:21",
  },
  {
    id: "J4u-IuxJ75Q",
    title: "ニホンミツバチ捕獲の強い味方！【待ち箱ルアー紹介】",
    duration: "7:07",
  },
  {
    id: "htqA4WF50UI",
    title: "ニホンミツバチ待ち箱【オススメの設置場所】注意点も紹介！",
    duration: "5:07",
  },
  {
    id: "NT8-aGr-jpw",
    title: "ニホンミツバチを誘引するキンリョウヘンの紹介",
    duration: "5:30",
  },
  {
    id: "3rIMLElb_RE",
    title: "1つの群の分蜂回数は何回？【週末養蜂場の分蜂まとめ2020】",
    duration: "6:54",
  },
  {
    id: "MCA0e_zgNbk",
    title:
      "逃げるミツバチの大群を追いかけたら捕まえられるのか！？【分蜂群捕獲チャレンジ】",
    duration: "4:47",
  },
  {
    id: "mJu_sTq6-vA",
    title:
      "逃げるミツバチの大群を200m追いかけて捕まえる！【分蜂群捕獲チャレンジ】",
    duration: "7:11",
  },
  {
    id: "Ju5YhBQGFJg",
    title: "【ニホンミツバチの開放巣】初めての分蜂に密着！！",
    duration: "7:27",
  },
  {
    id: "SX9Kwb2RCrk",
    title:
      "怖くない！お引越し中の日本ミツバチ【ミツバチの大群に頬ずりしてみた！】",
    duration: "6:55",
  },
  {
    id: "x5ALX5DNuiU",
    title: "効率的に群を増やす！【ニホンミツバチ強制捕獲のコツ】",
    duration: "5:03",
  },
  {
    id: "0im_t71O3O4",
    title: "ニホンミツバチを飼う前に知っておきたいこと６選【養蜂初心者向け】",
    duration: "9:00",
  },
  {
    id: "-Pi3wKLmbHw",
    title:
      "【Q&A】ニホンミツバチの養蜂を始めたい！【初心者のよくある質問４つ紹介】",
    duration: "7:59",
  },
  {
    id: "8ONbk_0-ATE",
    title:
      "【Q&A】マンションでも養蜂できる？ニホンミツバチ飼育場所のよくある質問",
    duration: "7:05",
  },
  {
    id: "sHdLp-UVjcw",
    title: "スムシの生態・対策について専門家に聞いてみた！後編",
    duration: "7:50",
  },
  {
    id: "79710ANIXGI",
    title: "スムシの生態・対策について専門家に聞いてみた！前編",
    duration: "9:22",
  },
  {
    id: "q1VWXUa3kKo",
    title: "ミツバチの外敵スムシを防除する！スムシっ子カード！",
    duration: "17:42",
  },
  {
    id: "1VbCn8unh5U",
    title: "ニホンミツバチ巣箱のスノコの作り方",
    duration: "8:25",
  },
  {
    id: "S2_kRuhgogk",
    title: "ニホンミツバチ巣箱の重箱の作り方",
    duration: "12:46",
  },
  {
    id: "ibMH_3jrGw0",
    title: "入手困難！？ニホンミツバチを誘引するキンリョウヘンの入手方法",
    duration: "5:03",
  },
  {
    id: "TemjBIA8e2Y",
    title: "【キンリョウヘン】花が咲く株・咲かない株の見分ける方法を紹介！",
    duration: "8:01",
  },
  {
    id: "RvyOb1JI8O4",
    title: "ニホンミツバチ捕獲に欠かせない！【蜜蝋紹介】",
    duration: "3:58",
  },
  {
    id: "Rnpg49cDpLg",
    title: "ニホンミツバチを捕まえる為に冬の間に準備しておくべきこと",
    duration: "8:37",
  },
  {
    id: "7VOBuL7jBq8",
    title: "ニホンミツバチの巣箱を置くのにオススメの場所紹介！",
    duration: "6:41",
  },
  {
    id: "GwiM58FybZU",
    title: "全然捕まえられない！【ニホンミツバチ捕獲に失敗する人の共通点】",
    duration: "5:34",
  },
  {
    id: "b9MqWHu-tcM",
    title: "ニホンミツバチの巣箱はここで作られる！【週末養蜂の巣箱工場ツアー】",
    duration: "7:30",
  },
  {
    id: "3CB61y0-UXI",
    title: "飼育歴20年！ニホンミツバチ養蜂のノウハウを詰めた教科書！",
    duration: "10:53",
  },
  {
    id: "jNyyTijU3HU",
    title: "長年の経験から生まれた工夫！【週末養蜂の重箱式巣箱紹介】",
    duration: "12:46",
  },
  {
    id: "xI0yHJrOl5o",
    title: "これだけで始められる！ニホンミツバチ飼育スタートキット紹介",
    duration: "24:16",
  },
  {
    id: "6bjh57EoX1s",
    title: "【徹底解説】ニホンミツバチ捕獲のコツを５つ紹介！",
    duration: "8:49",
  },
  {
    id: "qfASW7oZ_kA",
    title: "ニホンミツバチを捕獲する！【待ち箱設置方法とその後の対応】",
    duration: "9:18",
  },
  {
    id: "Q91tUtMybtI",
    title: "期待しすぎ！？間違いだらけ！？【待ち箱ルアーのよくある誤解！】",
    duration: "14:03",
  },
  {
    id: "cfoLRcV5EME",
    title:
      "どんな巣箱が捕獲できる？【ニホンミツバチ捕獲におすすめの待ち箱紹介】",
    duration: "8:28",
  },
  {
    id: "dYWjyEz9pMU",
    title: "ベテラン飼育者がニホンミツバチをたくさん捕獲できる秘訣！",
    duration: "10:31",
  },
  {
    id: "FIcSYpLf7pY",
    title: "キンリョウヘンの親戚！フォアゴットンフルーツのミツバチ誘引力！",
    duration: "9:16",
  },
  {
    id: "VWFRkS2wzaI",
    title: "ニホンミツバチ巣箱の移動方法〜捕獲した当日編〜",
    duration: "9:27",
  },
  {
    id: "m2sVcMrQx9M",
    title:
      "【悲報】分蜂したけど捕まえられない！？○○○に集合して手も足も出ず....",
    duration: "5:31",
  },
  {
    id: "g8Etbnt18AY",
    title: "野鳥の巣箱設置・観察と似てる！？ニホンミツバチの飼育",
    duration: "6:52",
  },
  {
    id: "mHYcymUovmY",
    title: "【ルーティーン】ミツバチハンター！養蜂家の春の忙しい一日",
    duration: "16:02",
  },
  {
    id: "v9gOuN608N0",
    title: "週末養蜂場大ピンチ！？分蜂しません・・・",
    duration: "10:01",
  },
  {
    id: "S2Foyjc0lzs",
    title: "8000匹のニホンミツバチ捕まえてみた！！",
    duration: "14:54",
  },
  {
    id: "6LHcgid-0Wk",
    title: "ニホンミツバチの大群を網で捕獲！【田舎暮らし】",
    duration: "10:03",
  },
  {
    id: "NmQSbsRooR8",
    title: "台風並みの雨の中、ブチギレてるニホンミツバチを捕獲！",
    duration: "15:02",
  },
  {
    id: "JFi3IlpYE1E",
    title: "死ぬ運命？一匹のはずの女王蜂が二匹いて殺し合いに....",
    duration: "27:41",
  },
  {
    id: "YYviUjXVeiI",
    title: "日本ミツバチの女王蜂が羽化する瞬間",
    duration: "1:58",
  },
  {
    id: "-YQ6qDTaxik",
    title:
      "目では見れない感動的なニホンミツバチの世界。知られざる体の秘密とは？【マクロレンズ】",
    duration: "9:57",
  },
  {
    id: "noUZPYPIRkI",
    title:
      "分蜂した野生のニホンミツバチを捕獲！反撃されたり、あり得ない事態にも遭遇...！？【2021総集編】",
    duration: "24:40",
  },
];
