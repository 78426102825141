import FirstStep from '../../components/forms/firstStep'
import { connect } from 'react-redux'
import { IRootState } from '../../reducers/rootReducer'
import { getCities } from '../../actions/prefs'

const mapStateToProps = (state: IRootState) => {
  return {
    options: state.formOptions.options,
    prefs: state.prefs.prefs,
    cities: state.prefs.cities,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCities: (prefId: string) => {
      dispatch(getCities(prefId))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FirstStep)
