import React, { Component } from "react";
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Link,
} from "@material-ui/core";
import "react-medium-image-zoom/dist/styles.css";
import { adYouTubeData } from "./youtubeAd";

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      minHeight: "160px",
    },
    item: {
      margin: "10px",
    },
    thumbnail: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    ad: {
      minHeight: "160px",
      marginTop: theme.spacing(4),
      width: "100%",
      "& a img": {
        maxWidth: "100%",
      },
    },
  });

interface IStyles extends WithStyles<typeof styles> {}

type IProps = {
  listItemIndex: number;
  mode: "youtube" | "mail";
} & IStyles;

type IState = {};

class ReportListAdItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: IProps) {
    if (this.props.listItemIndex === nextProps.listItemIndex) {
      return false;
    }
    return true;
  }

  renderMailBanner = () => {
    const { classes } = this.props;
    return (
      <div className={classes.ad}>
        <a
          href="https://mailchi.mp/beekeep/swarm-map"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://s3-ap-northeast-1.amazonaws.com/mitsubachi-map/site/ad/ad-mail-magazine-pc-1.png"
            alt=""
          />
        </a>
      </div>
    );
  };

  renderYouTubeCard(vid: string, title: string) {
    const { classes } = this.props;
    return (
      <Link
        target="_blank"
        href={`https://www.youtube.com/watch?v=${vid}&list=PLBXLf1dU_ikRjd-BWDHBZatfCKaqMZCBK`}
        underline="none"
      >
        <Card className={classes.item}>
          <CardMedia
            className={classes.thumbnail}
            image={`https://i.ytimg.com/vi/${vid}/hqdefault.jpg`}
          />
          <CardContent>
            <Typography variant="body1" color="textPrimary" component="p">
              {title}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              component="span"
            >
              週末養蜂チャンネル
            </Typography>
          </CardContent>
        </Card>
      </Link>
    );
  }

  render() {
    const { classes } = this.props;
    const ad1 = adYouTubeData[Math.floor(Math.random() * adYouTubeData.length)];
    const ad2 = adYouTubeData[Math.floor(Math.random() * adYouTubeData.length)];

    if (this.props.mode === "mail") {
      return this.renderMailBanner();
    } else {
      return (
        <Grid container className={classes.ad}>
          <Grid item xs={6}>
            {this.renderYouTubeCard(ad1.id, ad1.title)}
          </Grid>
          <Grid item xs={6}>
            {this.renderYouTubeCard(ad2.id, ad2.title)}
          </Grid>
        </Grid>
      );
    }
  }
}

export default withStyles(styles)(ReportListAdItem);
