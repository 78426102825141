import { createStore, applyMiddleware, compose } from 'redux'
import { rootReducer } from '../reducers/rootReducer'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

const loggerMiddleware = createLogger({
  diff: true,
  collapsed: true,
})

const configureStore = (initialState?: object) => {
  let enhancer = null
  if (process.env.NODE_ENV === 'development') {
    enhancer = compose(applyMiddleware(loggerMiddleware, thunk))
  } else {
    enhancer = compose(applyMiddleware(thunk))
  }
  return createStore(rootReducer, initialState!, enhancer)
}

const store = configureStore({})
export default store
