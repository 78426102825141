import React, { Component } from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import {
  Theme,
  createStyles,
  Typography,
  Button,
  Grid,
} from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/styles'
import MyTextField from './textField'
import FileField from './fileField'
import validate from './validate'
import Compressor from 'compressorjs'

const IMG_QUALITY = 0.8
const IMG_MAX_WIDTH = 1000
const IMG_MAX_HEIGHT = 1000

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 300,
      width: '60%',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    file: {
      display: 'none',
    },
    resetButton: {
      margin: theme.spacing(1),
      marginTop: 0,
    },
  })

interface IState {
  imageFile: any
}
interface IStyles extends WithStyles<typeof styles> {}
interface IStepProps {
  prevStep: () => void
  clearField: (field: string) => void
  getAnswer: () => void
  setCheckedImageDelete: (checked: boolean) => void
  description: string
  image: any
  imageUrl?: string
  checkedImageDelete: boolean
}
type IProps = IStepProps & IStyles

class ThirdStep extends Component<
  IProps & InjectedFormProps<{}, IProps>,
  IState
> {
  constructor(props: IProps & InjectedFormProps<{}, IProps>) {
    super(props)
    this.state = {
      imageFile: null,
    }
  }

  componentDidMount = () => {
    if (this.props.image && !this.state.imageFile) {
      const file = this.props.image
      this.setFile(file)
    }
    this.props.getAnswer()
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>, inputChange: any) => {
    e.preventDefault()
    // tslint:disable-next-line: no-this-assignment
    const that = this

    if (e.target.files![0]) {
      const file = e.target.files![0]
      const payload: Compressor.Options = {
        quality: IMG_QUALITY,
        maxWidth: IMG_MAX_WIDTH,
        maxHeight: IMG_MAX_HEIGHT,
        success(blob: Blob): void {
          const reader = new FileReader()
          reader.onloadend = () => {
            const result = reader.result
            if (result instanceof ArrayBuffer || result === null) return
            that.setState({
              imageFile: result,
            })
          }
          reader.readAsDataURL(blob)
          const resizedFile = new File([blob], file.name, { type: file.type })
          inputChange(resizedFile)
        },
        error(err: Error): void {
          console.error(err.message)
        },
      }
      new Compressor(file, payload)
    } else {
      this.setState({
        imageFile: null,
      })
    }
  }

  checkedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setCheckedImageDelete(event.target.checked)
  }

  setFile = (file: any) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      this.setState({
        imageFile: reader.result,
      })
    }
    reader.readAsDataURL(file)
  }

  resetFile = () => {
    this.setState({
      imageFile: null,
    })
    this.props.clearField('image')
  }

  render() {
    const { handleSubmit, pristine, invalid, classes } = this.props
    let myPristine = pristine
    if (this.props.description) {
      myPristine = false
    }
    return (
      <form onSubmit={handleSubmit}>
        <Typography variant="h6" gutterBottom={true}>
          捕獲の内容
        </Typography>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <Field
              id="description"
              name="description"
              required={true}
              component={MyTextField}
              label="捕獲時の内容"
              placeholder="捕獲時の内容を入力してください。"
              rows="3"
              rowsMax="6"
              multiline={true}
              margin="dense"
              className={classes.textField}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              id="image"
              name="image"
              required={false}
              component={FileField}
              accept="image/jpeg,image/png,image/gif"
              label="画像"
              onFieldChange={this.handleChange}
              onCheckedChange={this.checkedChange}
              onResetFile={this.resetFile}
              imageFile={this.state.imageFile}
              imageUrl={this.props.imageUrl}
              checkedImageDelete={this.props.checkedImageDelete}
              classes={classes}
            />
          </Grid>
          {this.state.imageFile ? (
            <Grid item={true} xs={12}>
              <Button
                type="button"
                onClick={this.resetFile}
                variant="outlined"
                className={classes.resetButton}
              >
                クリア
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} className={classes.buttonContainer}>
            <Button
              color="secondary"
              variant="outlined"
              type="button"
              onClick={this.props.prevStep}
            >
              戻る
            </Button>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              disabled={myPristine || invalid}
            >
              次へ
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default withStyles(styles)(
  reduxForm<{}, IProps>({
    validate,
    form: 'report',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(ThirdStep),
)
