import React from 'react'
import { FileFieldProps } from './form'
import {
  FormControl,
  FormLabel,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

const FileField: React.SFC<FileFieldProps> = ({
  input: { value, name, onChange },
  label,
  meta: { touched, error },
  classes,
  onFieldChange,
  onCheckedChange,
  imageFile,
  imageUrl,
  checkedImageDelete,
  buttonLabel,
  required = false,
  accept = '*',
  ...custom
}) => {
  return (
    <FormControl
      component="fieldset"
      required={required}
      error={!!(touched && error)}
      className={classes.textField}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <input
        accept={accept}
        className={classes.file}
        id={name}
        type="file"
        multiple={false}
        onChange={e => {
          e.preventDefault()
          onFieldChange && onFieldChange(e, onChange)
        }}
      />
      <label htmlFor={name}>
        <Button variant="outlined" component="span">
          {buttonLabel || 'ファイルを選択'}
        </Button>
      </label>
      <label>{value && value.name}</label>
      {imageFile ? (
        <div>
          <img
            style={{ height: '50%', width: '50%' }}
            src={imageFile}
            alt="preview"
          />
        </div>
      ) : imageUrl ? (
        <Grid container={true}>
          <Grid item={true}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedImageDelete}
                  onChange={onCheckedChange}
                  value="checked"
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />
              }
              label="削除する"
            />
          </Grid>
          <Grid item={true}>
            <img style={{ maxWidth: '50%' }} src={imageUrl} alt="preview" />
          </Grid>
        </Grid>
      ) : (
        <div style={{ fontSize: '12px' }}>
          ファイルサイズは10MB以下にしてください。
        </div>
      )}
    </FormControl>
  )
}

export default FileField
