import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as actions from '../actions/deleteDialog'

export type DeleteDialogState = {
  readonly isOpen: boolean
  readonly targetReportId: string
  readonly isRequesting: boolean
  readonly isSuccess: boolean | null
  readonly isError: boolean | null
}

const initialState: DeleteDialogState = {
  isOpen: false,
  targetReportId: '',
  isRequesting: false,
  isSuccess: null,
  isError: null,
}

export default reducerWithInitialState(initialState)
  .case(actions.deleteReportRequest, (state: DeleteDialogState) => ({
    ...state,
    isRequesting: true,
    isSuccess: null,
    isError: null,
  }))
  .case(actions.deleteReportSuccess, (state: DeleteDialogState) => ({
    ...state,
    isRequesting: false,
    isSuccess: true,
    isError: false,
  }))
  .case(actions.deleteReportError, (state: DeleteDialogState) => ({
    ...state,
    isRequesting: false,
    isSuccess: false,
    isError: true,
  }))
  .case(
    actions.openDeleteDialog,
    (state: DeleteDialogState, targetReportId: string) => ({
      ...state,
      isOpen: true,
      targetReportId,
    }),
  )
  .case(actions.closeDeleteDialog, (state: DeleteDialogState) => ({
    ...state,
    isOpen: false,
    isSuccess: null,
    isError: null,
    targetReportId: '',
  }))
  .build()
