import FourthStep from '../../components/forms/fourthStep'
import { connect } from 'react-redux'
import { IRootState } from '../../reducers/rootReducer'

const mapStateToProps = (state: IRootState) => {
  return {
    question: state.spamCheck.question,
    answer: state.spamCheck.answer,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FourthStep)
