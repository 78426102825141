import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as actions from '../actions/editConfirmDialog'

export type EditConfirmState = {
  readonly isOpen: boolean
  readonly targetReportId: string
  readonly isRequesting: boolean
  readonly isSuccess: boolean | null
  readonly isError: boolean | null
}

const initialState: EditConfirmState = {
  isOpen: false,
  targetReportId: '',
  isRequesting: false,
  isSuccess: null,
  isError: null,
}

export default reducerWithInitialState(initialState)
  .case(
    actions.openEditConfirmDialog,
    (state: EditConfirmState, targetReportId: string) => ({
      ...state,
      isOpen: true,
      targetReportId,
    }),
  )
  .case(actions.closeEditConfirmDialog, (state: EditConfirmState) => ({
    ...state,
    isOpen: false,
    isSuccess: null,
    isError: null,
    targetReportId: '',
  }))
  .case(actions.editConfirmRequest, (state: EditConfirmState) => ({
    ...state,
    isRequesting: true,
    isSuccess: null,
    isError: null,
  }))
  .case(actions.editConfirmSuccess, (state: EditConfirmState) => ({
    ...state,
    isRequesting: false,
    isSuccess: true,
    isError: false,
  }))
  .case(actions.editConfirmError, (state: EditConfirmState) => ({
    ...state,
    isRequesting: false,
    isSuccess: false,
    isError: true,
  }))
  .build()
