import webAPI from '../apis/webAPI'
import actionCreatorFactory from 'typescript-fsa'
import { IRootState } from '../reducers/rootReducer'
import { Dispatch } from 'redux'

type GetState = () => IRootState
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
const actionCreator = actionCreatorFactory()

export const getSpamCheckRequest = actionCreator('GET_SPAM_CHECK_REQUEST')
export const getSpamCheckQuestionSuccess = actionCreator<string>(
  'GET_SPAM_CHECK_QUESTION_SUCCESS',
)
export const getSpamCheckAnswerSuccess = actionCreator<string>(
  'GET_SPAM_CHECK_ANSWER_SUCCESS',
)
export const getSpamCheckError = actionCreator('GET_SPAM_CHECK_ERROR')

export const getSpamCheckQuestion = (): ThunkAction => async (
  dispatch: Dispatch,
) => {
  dispatch(getSpamCheckRequest())
  webAPI
    .get('/reports/get_question', {})
    .then(res => {
      const question = res.data.question
      dispatch(getSpamCheckQuestionSuccess(question))
    })
    .catch(error => {
      console.log(error)
      dispatch(getSpamCheckError())
    })
}

export const getSpamCheckAnswer = (): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const question = getState().spamCheck.question
  if (!question) {
    return
  }
  dispatch(getSpamCheckRequest())
  webAPI
    .get(`/reports/get_answer/${question}`)
    .then(res => {
      const answer = res.data.answer
      dispatch(getSpamCheckAnswerSuccess(answer))
    })
    .catch(error => {
      console.log(error)
      dispatch(getSpamCheckError)
    })
}
