import React from 'react'
import { createStyles, Theme, Typography, IconButton } from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/styles'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: 'white',
    },
  })
interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: (event: React.SyntheticEvent<{}, Event>) => void
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle
      disableTypography={true}
      className={classes.dialogTitle}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export default withStyles(styles)(DialogTitle)
