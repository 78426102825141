import { IRootState } from '../reducers/rootReducer'
import { ILatLngBounds, ILatLng } from '../types'
import {
  setMapBounds,
  createClusters,
  setMapCenter,
  setMapZoom,
} from '../actions/reportLocations'
import { getReportListInBounds } from '../actions/reportList'
import { connect } from 'react-redux'
import MyMap from '../components/myMap'

const mapStateToProps = (state: IRootState) => {
  return {
    mapCenter: state.reportLocations.mapCenter,
    mapZoom: state.reportLocations.mapZoom,
    clusterAreaRendered: state.reportLocations.clusterAreaRendered,
    checkedInBounds: state.reportList.checkedInBounds,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setBounds: (bounds: ILatLngBounds) => {
      dispatch(setMapBounds(bounds))
    },
    calcClusters: () => {
      dispatch(createClusters())
    },
    setCenter: (center: ILatLng) => {
      dispatch(setMapCenter(center))
    },
    setZoom: (zoom: number) => {
      dispatch(setMapZoom(zoom))
    },
    getReportListInBounds: () => {
      dispatch(getReportListInBounds())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyMap)
