import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { IPrefs, ICities } from '../types'
import * as actions from '../actions/prefs'

export type PrefState = {
  readonly prefs: IPrefs
  readonly cities: ICities
  isRequesting: boolean
  isError: boolean | null
}

const initialState: PrefState = {
  prefs: [],
  cities: [],
  isRequesting: false,
  isError: null,
}

export default reducerWithInitialState(initialState)
  .case(actions.getPrefsRequest, (state: PrefState) => ({
    ...state,
    isRequesting: true,
    isError: null,
  }))
  .case(actions.getPrefsSuccess, (state: PrefState, prefs: IPrefs) => ({
    ...state,
    prefs,
    isRequesting: false,
    isError: false,
  }))
  .case(actions.getPrefsError, (state: PrefState) => ({
    ...state,
    isRequesting: false,
    isError: true,
  }))
  .case(actions.getCitiesRequest, (state: PrefState) => ({
    ...state,
    isRequesting: true,
    isError: null,
  }))
  .case(actions.getCitiesSuccess, (state: PrefState, cities: IPrefs) => ({
    ...state,
    cities,
    isRequesting: false,
    isError: false,
  }))
  .case(actions.getCitiesError, (state: PrefState) => ({
    ...state,
    isRequesting: false,
    isError: true,
  }))
  .build()
