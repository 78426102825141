import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as actions from '../actions/reportDetails'
import { IReportDetail, IReportDetailsFilter } from '../types'

export type ReportDetailsState = {
  readonly reportDetails: IReportDetail[]
  readonly filter: IReportDetailsFilter
  readonly isRequesting: boolean
  readonly isError: boolean | null
}
export const createDefaultDetailsFilter = (): IReportDetailsFilter => {
  return {
    nextPage: 1,
    mode: '',
    ids: [],
    range: { start: '', end: '' },
  }
}
const initialState: ReportDetailsState = {
  reportDetails: [],
  filter: createDefaultDetailsFilter(),
  isRequesting: false,
  isError: null,
}

export default reducerWithInitialState(initialState)
  .case(actions.getReportDetailsRequest, (state: ReportDetailsState) => ({
    ...state,
    isRequesting: true,
    isError: null,
  }))
  .case(
    actions.getReportDetailsSuccess,
    (state: ReportDetailsState, reports: IReportDetail[]) => ({
      ...state,
      reportDetails: state.reportDetails.concat(reports),
      isRequesting: false,
      isError: false,
    }),
  )
  .case(actions.getReportDetailsError, (state: ReportDetailsState) => ({
    ...state,
    isRequesting: false,
    isError: true,
  }))
  .case(
    actions.setReportDetailsFilterDate,
    (state: ReportDetailsState, range: { start: string; end: string }) => ({
      ...state,
      filter: {
        ...state.filter,
        mode: 'date',
        nextPage: 1,
        ids: [],
        range,
      },
      reportDetails: [],
    }),
  )
  .case(
    actions.setReportDetailsFilterIds,
    (state: ReportDetailsState, ids: number[]) => ({
      ...state,
      filter: {
        ...state.filter,
        ids,
        mode: 'ids',
        nextPage: 1,
        range: { start: '', end: '' },
      },
      reportDetails: [],
    }),
  )
  .case(
    actions.setReportDetailsNextPage,
    (state: ReportDetailsState, nextPage: number) => ({
      ...state,
      filter: {
        ...state.filter,
        nextPage,
      },
    }),
  )
  .case(
    actions.setReportDetailsFilter,
    (state: ReportDetailsState, filter: IReportDetailsFilter) => ({
      ...state,
      filter,
    }),
  )
  .build()
