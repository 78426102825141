import React, { Component } from 'react'
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  ListItem,
  ListItemText,
  Grid,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      minHeight: '160px',
      flexGrow: 1,
    },
    listItemText: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    mb: {
      marginBottom: theme.spacing(0.5),
    },
  })
interface IStyles extends WithStyles<typeof styles> {}
type IProps = {} & IStyles

class LoadingListItem extends Component<IProps> {
  render() {
    const { classes } = this.props
    return (
      <ListItem
        alignItems="flex-start"
        divider={true}
        className={classes.listItem}
      >
        <ListItemText
          disableTypography={true}
          className={classes.listItemText}
          primary={
            <Grid
              container={true}
              spacing={1}
              className={classes.mb}
              direction="column"
            >
              <Grid item={true} xs={true}>
                <Skeleton variant="rect" width={600} height={32} />
              </Grid>
            </Grid>
          }
          secondary={
            <Grid container={true} spacing={1} direction="column">
              <Grid item={true} xs={true}>
                <Skeleton variant="rect" width={256} height={24} />
              </Grid>
              <Grid item={true} xs={true}>
                <Skeleton variant="rect" width={880} height={80} />
              </Grid>
            </Grid>
          }
        />
      </ListItem>
    )
  }
}

export default withStyles(styles)(LoadingListItem)
