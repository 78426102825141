import { Dispatch } from 'redux'
import webAPI from '../apis/webAPI'
import actionCreatorFactory, { AnyAction } from 'typescript-fsa'
import {
  IReportItemLocation,
  IReportLocations,
  IReportLocationsFilter,
  ILatLngBounds,
  ILatLng,
} from '../types'
import { IRootState } from '../reducers/rootReducer'
import { getCluster, getClusterArea } from '../components/mapUtil'
import { BBox } from 'geojson'
import { getReportListInBoundsImpl } from './reportList'

type GetState = () => IRootState
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any
const actionCreator = actionCreatorFactory()

export const getReportLocationsRequest = actionCreator(
  'GET_REPORT_LOCATIONS_REQUEST',
)
export const getReportLocationsSuccess = actionCreator<IReportLocations>(
  'GET_REPORT_LOCATIONS_SUCCESS',
)
export const getReportLocationsError = actionCreator(
  'GET_REPORT_LOCATIONS_ERROR',
)
export const resetReportLocationsFilter = actionCreator(
  'RESET_LOCATIONS_FILTER',
)
export const setReportLocationsFilter = actionCreator<IReportLocationsFilter>(
  'SET_REPORT_LOCATIONS_FILTER',
)
export const setReportLocationsClusters = actionCreator<{
  clusters: any[]
  clusterMarkerIds: number[][]
  clusterAreaRendered: BBox
}>('SET_REPORT_LOCATIONS_CLUSTERS')
export const setMapBounds = actionCreator<ILatLngBounds>('SET_MAP_BOUNDS')
export const setMapCenter = actionCreator<ILatLng>('SET_MAP_CENTER')
export const setMapZoom = actionCreator<number>('SET_MAP_ZOOM')
export const setNewLocation = actionCreator<IReportItemLocation>(
  'SET_NEW_LOCATION',
)
export const setUpdatedLocation = actionCreator<IReportItemLocation>(
  'SET_UPDATED_LOCATION',
)

export const getReportLocations = (): ThunkAction | AnyAction => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const filter = getState().reportLocations.filter
  dispatch(getReportLocationsRequest())

  const params = {
    start: filter.start,
    end: filter.end,
  }

  webAPI
    .get('/reports/list', { params })
    .then(res => {
      const result: IReportLocations = {
        newCount: res.data.new_count,
        locations: res.data.locations.map((loc: any) => ({
          id: loc.id,
          locations: {
            latitude: loc.pos[0],
            longitude: loc.pos[1],
          },
        })),
      }
      dispatch(getReportLocationsSuccess(result))
      dispatch(createClusters() as AnyAction)
      getReportListInBoundsImpl(dispatch, getState)
    })
    .catch(error => {
      console.log(error)
      dispatch(getReportLocationsError())
      return
    })
}

export const createClusters = (): ThunkAction | AnyAction => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  createClustersImpl(dispatch, getState)
}

export const createClustersImpl = (dispatch: Dispatch, getState: GetState) => {
  const bounds = getState().reportLocations.bounds
  const locations = getState().reportLocations.locations.locations

  if (!bounds) {
    return
  }
  if (locations.length <= 0) {
    dispatch(
      setReportLocationsClusters({
        clusters: [],
        clusterMarkerIds: [[]],
        clusterAreaRendered: getClusterArea(bounds),
      }),
    )
    return
  }
  const cluster = getCluster(locations, bounds)
  const clusterMarkerIds: number[][] = [[]]

  // 1つのクラスターあたりに取得する
  const MAX_LEAVES = 99

  if (cluster.markers) {
    cluster.markers.map((marker: any, index: any) => {
      if (marker.id) {
        const clusterId = Number(marker.id)
        const leaves = cluster.cluster.getLeaves(clusterId, MAX_LEAVES)
        const ids = leaves.map((lef: any) => {
          return lef.properties.item
        })
        clusterMarkerIds[clusterId] = ids
      }
      return false
    })
    dispatch(
      setReportLocationsClusters({
        clusters: cluster.markers,
        clusterMarkerIds,
        clusterAreaRendered: getClusterArea(bounds),
      }),
    )
  } else {
    dispatch(
      setReportLocationsClusters({
        clusters: [],
        clusterMarkerIds: [[]],
        clusterAreaRendered: getClusterArea(bounds),
      }),
    )
  }
}
