import React, { Component } from 'react'
import { WithStyles, Theme, createStyles, withStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Header from '../../containers/headerContainer'

// markdown
import md from '../../contents/markdown'

interface IState {
  mdText: string
}
interface IStyles extends WithStyles<typeof styles> {}
interface IAppProps {
  markdownFileName: string
  hasHeader: boolean
}
type IProps = IAppProps & IStyles

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
  })

class StaticPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      mdText: '',
    }
  }

  componentDidMount() {
    fetch(require(`../../contents/${this.props.markdownFileName}.md`))
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          mdText: text,
        })
      })
  }

  render() {
    const { classes, hasHeader } = this.props
    return (
      <div className={classes.root}>
        {hasHeader && <Header isMapPage={false} />}
        <Container maxWidth="md">
          <Grid container={true} justify="center">
            <Grid item={true} xs={12} md={11}>
              <Box pt={3}>
                <Typography
                  variant="body1"
                  component="div"
                  dangerouslySetInnerHTML={{
                    __html: md.render(this.state.mdText),
                  }}
                />
              </Box>
              <style jsx={true} global={true}>{`
                h3 {
                  font-size: 2rem !important;
                }
                h5 {
                  font-size: 1.2rem !important;
                }
                img {
                  width: 100%;
                }
              `}</style>
            </Grid>
          </Grid>
        </Container>
        <style jsx={true} global={true}>{``}</style>
      </div>
    )
  }
}

export default withStyles(styles)(StaticPage)
