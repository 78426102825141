import { connect } from 'react-redux'
import EditConfirmDialog from '../../components/dialogs/editConfirm'
import {
  checkEditKey,
  closeEditConfirmDialog,
} from '../../actions/editConfirmDialog'
import { IRootState } from '../../reducers/rootReducer'

const mapStateToProps = (state: IRootState) => {
  return {
    isOpen: state.editConfirm.isOpen,
    isSuccess: state.editConfirm.isSuccess,
    isError: state.editConfirm.isError,
    isRequesting: state.editConfirm.isRequesting,
    targetPostId: state.editConfirm.targetReportId,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    checkEditKey: (editKey: string) => {
      dispatch(checkEditKey(editKey))
    },
    closeEditConfirmDialog: () => {
      dispatch(closeEditConfirmDialog())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditConfirmDialog)
