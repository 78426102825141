import { connect } from 'react-redux'
import { IRootState } from '../reducers/rootReducer'
import { openDialog } from '../actions/reportForm'
import {
  setReportLocationsFilter,
  getReportLocations,
} from '../actions/reportLocations'
import Header from '../components/header'
import { setHeadConditionYear } from '../actions/headCondition'

const mapStateToProps = (state: IRootState) => {
  return {
    year: state.headCondition.year,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    openDialog: () => {
      dispatch(openDialog())
    },
    updateMapLocations: (start: string, end: string) => {
      dispatch(setReportLocationsFilter({ start, end, periodType: 'date' }))
      dispatch(getReportLocations())
    },
    setHeadConditionYear: (year: number) => {
      dispatch(setHeadConditionYear(year))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header)
