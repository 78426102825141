import { PrefState } from '../reducers/prefs'
import { IPrefs, ICities } from '../types'
import actionCreatorFactory from 'typescript-fsa'
import { Dispatch } from 'redux'
import webAPI from '../apis/webAPI'

type GetState = () => PrefState
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any

const actionCreator = actionCreatorFactory()

export const getPrefsRequest = actionCreator('GET_PREFS_REQUEST')
export const getPrefsSuccess = actionCreator<IPrefs>('GET_PREFS_SUCCESS')
export const getPrefsError = actionCreator('GET_PREFS_ERROR')

export const getCitiesRequest = actionCreator('GET_CITIES_REQUEST')
export const getCitiesSuccess = actionCreator<ICities>('GET_CITIES_SUCCESS')
export const getCitiesError = actionCreator('GET_CITIES_ERROR')

export const getPrefs = (): ThunkAction => async (dispatch: Dispatch) => {
  dispatch(getPrefsRequest())
  webAPI
    .get('/reports/pref_list', {})
    .then(res => {
      dispatch(getPrefsSuccess(res.data))
    })
    .catch(error => {
      console.log(error)
      dispatch(getPrefsError())
    })
}

export const getCities = (prefId: string): ThunkAction => async dispatch => {
  dispatch(getCitiesRequest())
  webAPI
    .get(`/reports/area_list/${prefId}`, {})
    .then(res => {
      dispatch(getCitiesSuccess(res.data))
    })
    .catch(error => {
      console.log(error)
      dispatch(getCitiesError())
    })
}
