import * as actions from '../actions/reportForm'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { IReportDetail } from '../types'

export type ReportFormState = {
  readonly isOpen: boolean
  readonly isEditOpen: boolean
  readonly isRequesting: boolean
  readonly isError: boolean | null
  readonly isSuccess: boolean | null
  readonly editKey: string
  readonly targetReport: IReportDetail | null
  readonly checkedImageDelete: boolean
}

const initialState: ReportFormState = {
  isOpen: false,
  isEditOpen: false,
  isRequesting: false,
  isError: null,
  isSuccess: null,
  editKey: '',
  targetReport: null,
  checkedImageDelete: false,
}

export default reducerWithInitialState(initialState)
  .case(actions.openDialog, (state: ReportFormState) => ({
    ...state,
    isOpen: true,
  }))
  .case(actions.closeDialog, (state: ReportFormState) => ({
    ...state,
    isOpen: false,
  }))
  .case(actions.openEditDialog, (state: ReportFormState, editKey: string) => ({
    ...state,
    isEditOpen: true,
    editKey,
  }))
  .case(actions.closeEditDialog, (state: ReportFormState) => ({
    ...state,
    isEditOpen: false,
    editKey: '',
    targetReport: null,
    checkedImageDelete: false,
  }))
  .case(actions.postReportRequest, (state: ReportFormState) => ({
    ...state,
    isRequesting: true,
  }))
  .case(actions.postReportSuccess, (state: ReportFormState) => ({
    ...state,
    isRequesting: false,
    isSuccess: true,
    isError: false,
  }))
  .case(actions.postReportError, (state: ReportFormState) => ({
    ...state,
    isRequesting: false,
    isError: true,
    isSuccess: false,
  }))
  .case(
    actions.setTargetReport,
    (state: ReportFormState, targetReport: any) => ({
      ...state,
      targetReport,
    }),
  )
  .case(
    actions.setCheckedImageDelete,
    (state: ReportFormState, checkedImageDelete: boolean) => ({
      ...state,
      checkedImageDelete,
    }),
  )
  .build()
