import React, { Component } from 'react'
import { WithStyles, Theme, createStyles, withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import MyMap from '../containers/myMapContainer'
import Header from '../containers/headerContainer'
import ClusterDialog from '../containers/dialogs/clusterDialogContainer'
import ReportDialog from '../containers/dialogs/reportDialogContainer'
import ReportList from '../containers/reportListContainer'
import EditReportDialog from '../containers/dialogs/editReportContainer'
import DocumentTitle from 'react-document-title'
import dayjs from 'dayjs'
import { Helmet } from 'react-helmet'

interface IState {}
interface IStyles extends WithStyles<typeof styles> {}
interface IAppProps {
  formIsOpen: boolean
  getReportLocations: () => void
  getFormOptions: () => void
  getPrefs: () => void
  getSpamCheckQuestion: () => void
}
type IProps = IAppProps & IStyles

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      height: '100vh',
      overflowY: 'hidden',
    },
    map: {
      height: '100%',
    },
    reportList: {
      height: '100%',
    },
  })

class Home extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.onBeforeunload = this.onBeforeunload.bind(this)
  }

  onBeforeunload(e: any) {
    e.preventDefault()
    if (this.props.formIsOpen) {
      const confirmationMessage = 'confirm message'
      e.returnValue = confirmationMessage
      return confirmationMessage
    }
    return
  }

  componentDidMount() {
    this.props.getReportLocations()
    this.props.getFormOptions()
    this.props.getPrefs()
    this.props.getSpamCheckQuestion()
    window.addEventListener('beforeunload', this.onBeforeunload)
  }

  render() {
    const { classes } = this.props
    const year = dayjs().year()
    const title = `分蜂マップ トップ ${year}`
    return (
      <DocumentTitle title={title}>
        <div className={classes.root}>
          <Helmet>
            <script async={true}>{`
            (function(w, d) { w.CollectId = "5a914ebe17b14142228a5d16"; var h = d.head || d.getElementsByTagName("head")[0]; var s = d.createElement("script"); s.setAttribute("type", "text/javascript"); s.setAttribute("src", "https://collectcdn.com/launcher.js"); h.appendChild(s); })(window, document);
            `}</script>
          </Helmet>
          <Header isMapPage={true} />
          <Grid container={true} className={classes.map}>
            <Grid item={true} xs={12} sm={6} className={classes.reportList}>
              <ReportList />
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <MyMap />
            </Grid>
          </Grid>
          <ClusterDialog />
          <ReportDialog />
          <EditReportDialog />
        </div>
      </DocumentTitle>
    )
  }
}

export default withStyles(styles)(Home)
