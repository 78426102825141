import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import { OverlayView } from 'react-google-maps'
import m33 from '../images/m33.png'
import m55 from '../images/m55.png'
import m77 from '../images/m77.png'
import ReactGA from 'react-ga'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
    },
    markerOuter: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
    },
    markerInner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    markerText: {
      color: '#ffffff',
      fontWeight: 'bold',
      marginBottom: 4,
    },
  })

interface IStyles extends WithStyles<typeof styles> {}
interface IState {}
interface IMyClusterMarkerProps {
  onClusterClick: (ids: number[]) => void
  clusters: any[]
  clusterMarkerIds: number[][]
}
type IProps = IMyClusterMarkerProps & IStyles

class MyClusterMarker extends Component<IProps, IState> {
  handleClick = (id: number | string) => {
    let ids: number[] = []
    if (typeof id === 'number') {
      ids = this.props.clusterMarkerIds[id]
    } else if (typeof id === 'string') {
      ids.push(Number(id))
    }
    this.props.onClusterClick(ids)
    ReactGA.event({
      category: 'cluster',
      action: 'click',
    })
  }

  getPixelPositionOffset = (width: number, height: number) => {
    return { x: -(width / 2), y: -(height / 2) }
  }

  renderMarker = (marker: any, index: any) => {
    const key = index + marker.geometry.coordinates[0]

    if (marker.properties.point_count >= 1) {
      const { classes } = this.props
      const mapPane = OverlayView.OVERLAY_MOUSE_TARGET
      const getOffset = this.getPixelPositionOffset.bind(this)
      const markers = [
        { size: 33, image: m33, font: 12 },
        { size: 55, image: m55, font: 16 },
        { size: 77, image: m77, font: 24 },
      ][Math.min(marker.properties.point_count - 1, 2)]
      const markerId: string = marker.id ? marker.id : marker.properties.item
      return (
        <OverlayView
          key={key}
          position={{
            lat: marker.geometry.coordinates[1],
            lng: marker.geometry.coordinates[0],
          }}
          mapPaneName={mapPane}
          getPixelPositionOffset={getOffset}
        >
          <div
            className={classes.markerOuter}
            style={{
              backgroundImage: `url(${markers.image})`,
              backgroundSize: 'cover',
              width: markers.size,
              height: markers.size,
            }}
            onClick={this.handleClick.bind(this, markerId)}
          >
            <div className={classes.markerInner}>
              <span
                className={classes.markerText}
                style={{ fontSize: markers.font }}
              >
                {marker.properties.point_count}
              </span>
            </div>
          </div>
        </OverlayView>
      )
    }
    return null
  }

  render() {
    return this.props.clusters.map((marker, index) =>
      this.renderMarker(marker, index),
    )
  }
}

export default withStyles(styles)(MyClusterMarker)
