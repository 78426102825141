import { IRootState } from '../../reducers/rootReducer'
import ClusterDialog from '../../components/dialogs/clusterDialog'
import { closeDialog } from '../../actions/clusterDialog'
import { connect } from 'react-redux'
import { getReportDetails } from '../../actions/reportDetails'

const mapStateToProps = (state: IRootState) => {
  return {
    reports: state.reportDetails.reportDetails,
    filter: state.reportDetails.filter,
    isOpen: state.clusterDialog.isOpen,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeDialog: () => {
      dispatch(closeDialog())
    },
    getReportDetails: () => {
      dispatch(getReportDetails())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClusterDialog)
