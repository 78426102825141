import React, { Component } from "react";
import { IReportDetail } from "../types";
import {
  ListItem,
  ListItemText,
  IconButton,
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Typography,
  Grid,
  Box,
  Icon,
} from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MoreVert, Today, AccessTime, Room } from "@material-ui/icons";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingBottom: theme.spacing(2),
      maxHight: "600px",
      overflowX: "scroll",
    },
    listItem: {
      minHeight: "160px",
    },
    listItemText: {
      marginTop: theme.spacing(4),
      marginBottom: "16px",
    },
    info: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    nickname: {
      fontSize: "24px",
      fontWeight: 400,
      lineHeight: "32px",
    },
    button: {
      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        margin: "4px",
      },
      color: "rgba(0, 0, 0, .58)",
      "&:focus": {
        backgroundColor: theme.palette.grey[500],
      },
    },
    lure: {
      position: "absolute",
      right: theme.spacing(),
      bottom: theme.spacing(),
      width: "74px",
      height: "85px",
      "& img": {
        width: "100%",
        filter: "drop-shadow(2px 2px 2px rgba(0,0,0,0.5))",
      },
    },
    rightButtom: {
      position: "absolute",
      right: 0,
      bottom: "8px",
    },
    bold: {
      fontWeight: "bold",
    },
    cover: {
      width: "100%",
      paddingTop: "80%",
    },
  });
interface IStyles extends WithStyles<typeof styles> {}
interface IState {
  anchorEl: HTMLElement | null;
}
interface IUserListProps {
  report: IReportDetail;
  style?: React.CSSProperties;
  openDeleteDialog: (id: string) => void;
  openEditConfirm: (id: string) => void;
}
type IProps = IUserListProps & IStyles;

class ReportListItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }
  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
    });
  };
  handleClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };
  handleDelete = (event: React.MouseEvent<HTMLElement>, id: string) => {
    this.handleClose();
    this.props.openDeleteDialog(id);
  };
  handleEdit = (event: React.MouseEvent<HTMLElement>, id: string) => {
    this.handleClose();
    this.props.openEditConfirm(id);
  };

  render() {
    const { classes } = this.props;
    const report = this.props.report;
    const nickname: string =
      report.nickname && report.nickname !== "null" ? report.nickname : "匿名";
    let hiveType = null;
    let hiveLocation = null;
    let using = null;
    if (report.show_options) {
      hiveType = (
        <React.Fragment>
          巣箱の種類： <span className={classes.bold}>{report.hive_type}</span>
        </React.Fragment>
      );
      hiveLocation = (
        <React.Fragment>
          設置場所詳細：{" "}
          <span className={classes.bold}>{report.hive_location}</span>
        </React.Fragment>
      );
      using = (
        <React.Fragment>
          誘引に利用したもの：{" "}
          <span className={classes.bold}>{report.using}</span>
        </React.Fragment>
      );
    }
    const lure = report.using_lure ? (
      <Grid className={classes.lure}>
        <a
          href="https://store.shopping.yahoo.co.jp/syumatsu-yoho/a5cba5dba5.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/label-logo.svg" alt="待ち箱ルアーを使用" />
        </a>
      </Grid>
    ) : null;
    const media = report.image ? (
      <Box width={{ xs: "100%", sm: "40%" }} ml={{ xs: 0, sm: 2 }}>
        <Zoom zoomMargin={80} image={{ src: "" }}>
          <img
            alt="イメージ"
            src={report.image}
            className="materialboxed responsive-img"
            style={{ width: "50em" }}
          />
        </Zoom>
      </Box>
    ) : null;

    return (
      <React.Fragment>
        <ListItem
          alignItems="flex-start"
          divider={true}
          className={classes.listItem}
        >
          <ListItemText
            disableTypography={true}
            className={classes.listItemText}
            primary={
              <Box display="flex" alignItems="center" mb={2} flexWrap="wrap">
                <Today />
                {report.catch_date}　<AccessTime />
                {report.time_zone}
                <Box display="flex" alignItems="center" ml={1} mr={1}>
                  {report.weather === "晴れ" && (
                    <Icon color="primary" className="fas fa-sun" />
                  )}
                  {report.weather === "雨" && (
                    <Icon
                      style={{ color: blue[500] }}
                      className="fas fa-cloud-rain"
                    />
                  )}
                  {report.weather === "大雨" && (
                    <Icon
                      style={{ color: blue[900] }}
                      className="fas fa-cloud-showers-heavy"
                    />
                  )}
                  {report.weather === "曇り" && (
                    <Icon style={{ color: "grey" }} className="fas fa-cloud" />
                  )}
                  {report.weather}
                </Box>
                <Box display="flex" alignItems="center">
                  <Room />
                  {report.pref}　{report.city}
                </Box>
              </Box>
            }
            secondary={
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} sm={true} container={true}>
                  <Grid
                    item={true}
                    xs={true}
                    container={true}
                    direction="column"
                    spacing={2}
                  >
                    <Grid item={true} xs={true}>
                      <Typography
                        gutterBottom={true}
                        className={classes.nickname}
                      >
                        {nickname}
                      </Typography>
                      <Typography variant="body2" gutterBottom={true}>
                        <span className={classes.bold}>
                          {report.catch_type}
                        </span>
                        　{hiveType}
                      </Typography>
                      <Typography variant="body2" gutterBottom={true}>
                        {report.description}
                      </Typography>
                      <Box mb={1}>{hiveLocation}</Box>
                      <Box>{using}</Box>
                    </Grid>
                  </Grid>
                  <Box position="absolute" right={0} top={0}>
                    <IconButton
                      className={classes.button}
                      aria-controls={`menu-${report.id}`}
                      aria-haspopup="true"
                      onClick={this.handleClick}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id={`menu-${report.id}`}
                      anchorEl={this.state.anchorEl}
                      keepMounted={true}
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleClose}
                    >
                      <MenuItem onClick={(e) => this.handleEdit(e, report.id)}>
                        編集する
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => this.handleDelete(e, report.id)}
                      >
                        削除する
                      </MenuItem>
                    </Menu>
                  </Box>
                  {media}
                  {lure}
                </Grid>
              </Grid>
            }
          />
        </ListItem>
        <style jsx={true}>{`
          .fas {
            min-width: 30px;
          }
          img.responsive-img {
            max-width: 100%;
            height: auto;
          }
          .materialboxed {
            display: block;
            cursor: zoom-in;
            position: relative;
            transition: opacity 0.4s;
          }
          img {
            max-width: 20em;
            display: block;
            margin: 0 auto;
          }
        `}</style>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ReportListItem);
