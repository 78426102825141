import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as actions from '../actions/reportLocations'
import {
  IReportLocations,
  IReportLocationsFilter,
  ILatLngBounds,
  ILatLng,
  IReportItemLocation,
} from '../types'
import { BBox } from 'geojson'
import dayjs from 'dayjs'

export type ReportLocationsState = {
  locations: IReportLocations
  bounds: ILatLngBounds | null
  clusters: any[]
  clusterMarkerIds: number[][]
  clusterAreaRendered: BBox
  filter: IReportLocationsFilter
  mapCenter: ILatLng
  mapZoom: number
  isRequesting: boolean
  isError: boolean | null
}

export const createDefaultLocationsFilter = (): IReportLocationsFilter => {
  const d = dayjs()
  return {
    start: d.format('YYYY-01-01'),
    end: d.format('YYYY-MM-DD'),
    periodType: 'thisyear',
  }
}

const initialState: ReportLocationsState = {
  locations: {
    newCount: 0,
    locations: [],
  },
  bounds: {
    ne: { lat: 39.67564438443721, lng: 142.20383066953127 },
    sw: { lat: 31.524879917320497, lng: 135.25497813046877 },
  },
  clusters: [],
  clusterMarkerIds: [[]],
  clusterAreaRendered: [0, 0, 0, 0],
  filter: createDefaultLocationsFilter(),
  mapCenter: { lat: 35.7043159, lng: 138.7294044 },
  mapZoom: 7,
  isRequesting: false,
  isError: null,
}

export default reducerWithInitialState(initialState)
  .case(actions.getReportLocationsRequest, (state: ReportLocationsState) => ({
    ...state,
    isRequesting: true,
    isError: null,
  }))
  .case(
    actions.getReportLocationsSuccess,
    (state: ReportLocationsState, locations: IReportLocations) => ({
      ...state,
      locations,
      isRequesting: false,
      isError: false,
    }),
  )
  .case(actions.getReportLocationsError, (state: ReportLocationsState) => ({
    ...state,
    isRequesting: false,
    isError: true,
  }))
  .case(actions.resetReportLocationsFilter, (state: ReportLocationsState) => ({
    ...state,
    filter: createDefaultLocationsFilter(),
  }))
  .case(
    actions.setReportLocationsFilter,
    (state: ReportLocationsState, filter: IReportLocationsFilter) => ({
      ...state,
      filter,
    }),
  )
  .case(
    actions.setReportLocationsClusters,
    (state: ReportLocationsState, payload) => ({
      ...state,
      clusters: payload.clusters,
      clusterMarkerIds: payload.clusterMarkerIds,
      clusterAreaRendered: payload.clusterAreaRendered,
    }),
  )
  .case(
    actions.setMapBounds,
    (state: ReportLocationsState, bounds: ILatLngBounds) => ({
      ...state,
      bounds,
    }),
  )
  .case(
    actions.setMapCenter,
    (state: ReportLocationsState, mapCenter: ILatLng) => ({
      ...state,
      mapCenter,
    }),
  )
  .case(actions.setMapZoom, (state: ReportLocationsState, mapZoom: number) => ({
    ...state,
    mapZoom,
  }))
  .case(
    actions.setNewLocation,
    (state: ReportLocationsState, newLocation: IReportItemLocation) => {
      const newLocations = state.locations.locations
      newLocations.unshift(newLocation)
      return {
        ...state,
        locations: {
          newCount: state.locations.newCount + 1,
          locations: newLocations,
        },
      }
    },
  )
  .case(
    actions.setUpdatedLocation,
    (state: ReportLocationsState, updatedLocation: IReportItemLocation) => {
      const oldLocations = state.locations.locations
      const index = oldLocations.findIndex(item => {
        return item.id === updatedLocation.id
      })
      oldLocations.splice(index, 1, updatedLocation)
      return {
        ...state,
        locations: {
          ...state.locations,
          locations: oldLocations,
        },
      }
    },
  )
  .build()
