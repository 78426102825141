import { combineReducers } from 'redux'
import ReportDetailsReducer, { ReportDetailsState } from './reportDetails'
import ReportLocationsReducer, { ReportLocationsState } from './reportLocations'
import ClusterDialogReducer, { ClusterDialogState } from './clusterDialog'
import ReportFormReducer, { ReportFormState } from './reportForm'
import { reducer as reduxFormReducer, FormReducer } from 'redux-form'
import FormOptionsReducer, { FormOptionsState } from './formOptions'
import PrefsReducer, { PrefState } from './prefs'
import SpamCheckReducer, { SpamCheckState } from './spamCheck'
import ReportListReducer, { ReportListState } from './reportList'
import DeleteDialogReducer, { DeleteDialogState } from './deleteDialog'
import EditConfirmDialogReducer, { EditConfirmState } from './editConfirmDialog'
import HeadConditionReducer, { HeadConditionState } from './headCondition'

export interface IRootState {
  reportLocations: ReportLocationsState
  reportDetails: ReportDetailsState
  clusterDialog: ClusterDialogState
  reportForm: ReportFormState
  form: FormReducer | any
  formOptions: FormOptionsState
  prefs: PrefState
  spamCheck: SpamCheckState
  reportList: ReportListState
  deleteDialog: DeleteDialogState
  editConfirm: EditConfirmState
  headCondition: HeadConditionState
}

export const rootReducer = combineReducers<IRootState>({
  reportLocations: ReportLocationsReducer,
  reportDetails: ReportDetailsReducer,
  clusterDialog: ClusterDialogReducer,
  reportForm: ReportFormReducer,
  form: reduxFormReducer,
  formOptions: FormOptionsReducer,
  prefs: PrefsReducer,
  spamCheck: SpamCheckReducer,
  reportList: ReportListReducer,
  deleteDialog: DeleteDialogReducer,
  editConfirm: EditConfirmDialogReducer,
  headCondition: HeadConditionReducer,
})
