import React from 'react'
import IFramePage from './iframePage'

const Feedback: React.SFC = props => {
  return (
    <IFramePage
      title="お問い合わせ"
      src="https://links.collect.chat/5dfb521fd66bd24b6228f7ea"
    />
  )
}

export default Feedback
