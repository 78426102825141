import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import MyTextField from './textField'
import DateField from './dateField'
import SelectField from './selectField'
import {
  Theme,
  createStyles,
  Grid,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/styles'
import validate from './validate'
import { IFormOptions, IPrefs, ICities } from '../../types'
import dayjs from 'dayjs'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 300,
      width: '60%',
    },
    selectField: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    inputLabel: {
      margin: theme.spacing(1),
    },
  })

interface IState {}
interface IStyles extends WithStyles<typeof styles> {}
interface IStepProps {
  getCities: (prefId: string) => void
  options: IFormOptions | null
  prefs: IPrefs
  cities: ICities
}
type IProps = IStepProps & IStyles

class FirstStep extends Component<
  IProps & InjectedFormProps<{}, IProps>,
  IState
> {
  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (this.props.cities.length <= 0 && prevProps.initialValues.pref) {
      this.props.getCities(prevProps.initialValues.pref)
    }
  }

  changePref = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.getCities(e.target.value)
  }

  render() {
    const { handleSubmit, pristine, invalid, classes } = this.props
    const timezones = this.props.options!.timeZone
    const prefs = this.props.prefs
    const cities = this.props.cities
    let myPristine = pristine
    if (cities.length > 0) {
      myPristine = false
    }
    return (
      <form onSubmit={handleSubmit}>
        <Typography variant="h6" gutterBottom={true}>
          捕獲日や場所などの基本情報を教えてください。
        </Typography>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <Field
              id="nickname"
              name="nickname"
              required={true}
              inputProps={{
                maxLength: 8,
              }}
              component={MyTextField}
              label="ニックネーム"
              placeholder="ニックネームを入力してください。(最大8文字)"
              className={classes.textField}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <Field
              id="catchDate"
              name="catchDate"
              required={true}
              label="捕獲日"
              className={classes.selectField}
              component={DateField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { max: dayjs().format('YYYY-MM-DD') },
              }}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl required={true}>
              <InputLabel htmlFor="timezone" className={classes.inputLabel}>
                時間帯
              </InputLabel>
              <Field
                id="timezone"
                name="timezone"
                required={true}
                component={SelectField}
                label="時間帯"
                className={classes.selectField}
              >
                <MenuItem>
                  <em>未選択</em>
                </MenuItem>
                {timezones.map(val => {
                  return (
                    <MenuItem value={val.code} key={val.code}>
                      {val.name}
                    </MenuItem>
                  )
                })}
              </Field>
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl required={true}>
              <InputLabel htmlFor="pref" className={classes.inputLabel}>
                都道府県
              </InputLabel>
              <Field
                id="pref"
                name="pref"
                required={true}
                component={SelectField}
                label="都道府県"
                className={classes.selectField}
                onChange={this.changePref}
              >
                <MenuItem>
                  <em>未選択</em>
                </MenuItem>
                {prefs.map(pref => {
                  return (
                    <MenuItem value={pref.id} key={pref.id}>
                      {pref.name}
                    </MenuItem>
                  )
                })}
              </Field>
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl required={true}>
              <InputLabel htmlFor="city" className={classes.inputLabel}>
                市町村
              </InputLabel>
              <Field
                id="city"
                name="city"
                required={true}
                component={SelectField}
                label="市町村"
                className={classes.selectField}
              >
                <MenuItem>
                  <em>未選択</em>
                </MenuItem>
                {cities.map(city => {
                  return (
                    <MenuItem value={city.id} key={city.id}>
                      {city.name}
                    </MenuItem>
                  )
                })}
              </Field>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container={true}>
          <Grid item={true} xs={12} style={{ textAlign: 'right' }}>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              disabled={myPristine || invalid}
            >
              次へ
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default withStyles(styles)(
  reduxForm<{}, IProps>({
    validate,
    form: 'report',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(FirstStep),
)
