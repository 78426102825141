import React, { Component } from 'react'
import {
  WithStyles,
  Theme,
  createStyles,
  withStyles,
  Typography,
  Link,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Header from '../containers/headerContainer'
import DocumentTitle from 'react-document-title'
import dayjs from 'dayjs'

interface IState {}
interface IStyles extends WithStyles<typeof styles> {}
interface IAppProps {}
type IProps = IAppProps & IStyles

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
    },
    wrap: {
      height: '100%',
      padding: theme.spacing(3),
    },
    content: {
      maxHeight: 'calc(100% - 64px)',
    },
  })

class WarningIE extends Component<IProps, IState> {
  render() {
    const { classes } = this.props
    const year = dayjs().year()
    const title = `分蜂マップ トップ ${year}`
    return (
      <DocumentTitle title={title}>
        <div className={classes.root}>
          <Header isMapPage={false} />
          <Grid container={true} className={classes.wrap}>
            <Grid item={true} xs={2} />
            <Grid item={true} xs={8} className={classes.content}>
              <Typography variant="h5" gutterBottom={true}>
                お使いの環境は分蜂マップに対応していません。
              </Typography>

              <Typography variant="body1" gutterBottom={true}>
                マイクロソフトエッジ(Microsoft Edge)や、グーグルクローム(Google
                Chrome)、ファイアーフォックス(Firefox)などの、インターネットエクスプローラ(Internet
                Explorer)以外のソフトで分蜂マップをお使いください。
              </Typography>

              <Typography variant="body1" gutterBottom={true}>
                現在使われているインターネットエクスプローラーは、製造元のマイクロソフトがセキュリティや最新技術への対応の問題から、利用をやめるように呼びかけています。
                こうした背景から、分蜂マップをより便利に安全に使っていただくため、インターネットエクスプローラーの対応を終了いたしました。
                お手数おかけいたしますが、他のソフトを使って分蜂マップをご利用ください。
              </Typography>

              <Typography variant="body1" gutterBottom={true}>
                発売から4年以内のほとんどのパソコンには、マイクロソフトのEdge(エッジ)というソフトがパソコンに入っており、分蜂マップをご利用いただけます。
                Edgeがインストールされていない場合は、グーグルのChromeをインストールしてご利用ください。
              </Typography>

              <Typography variant="body1">
                グーグルクローム(Google Chrome)のインストールは
                <Link href="https://www.google.com/chrome/">こちら</Link>
              </Typography>
            </Grid>
            <Grid item={true} xs={2} />
          </Grid>
        </div>
      </DocumentTitle>
    )
  }
}

export default withStyles(styles)(WarningIE)
