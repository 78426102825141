import { connect } from 'react-redux'
import { IRootState } from '../../reducers/rootReducer'
import { reset } from 'redux-form'
import { closeEditDialog, updateReport } from '../../actions/reportForm'
import EditReportDialog from '../../components/dialogs/editReport'
import { getCities } from '../../actions/prefs'
import { closeDialog } from '../../actions/clusterDialog'

const mapStateToProps = (state: IRootState) => {
  return {
    isOpen: state.reportForm.isEditOpen,
    isSuccess: state.reportForm.isSuccess,
    isError: state.reportForm.isError,
    targetReport: state.reportForm.targetReport,
    options: state.formOptions.options,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeDialog: () => {
      dispatch(closeEditDialog())
    },
    updateReport: (values: any) => {
      dispatch(updateReport(values))
    },
    resetForm: () => {
      dispatch(reset('report'))
    },
    getCities: (prefId: string) => {
      dispatch(getCities(prefId))
    },
    closeClusterDialog: () => {
      dispatch(closeDialog())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditReportDialog)
