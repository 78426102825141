import React, { Component } from 'react'
import {
  createStyles,
  Theme,
  CircularProgress,
  Dialog,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Button,
} from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/styles'
import DialogTitle from './myDialogTitle'
import FirstStep from '../../containers/forms/firstStepContainer'
import SecondStep from '../../containers/forms/secondStepContainer'
import ThirdStep from '../../containers/forms/thirdStepContainer'
import FourthStep from '../../containers/forms/fourthStepContainer'
import { IReportDetail, IFormOption, IFormOptions } from '../../types'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: 18,
    },
    content: {
      minWidth: 600,
      overflowY: 'unset',
    },
    stepper: {
      fontSize: 16,
    },
    dialogTitle: {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
  })

interface IState {
  step: number
}
interface IStyles extends WithStyles<typeof styles> {}
interface IEditReportProps {
  closeDialog: () => void
  updateReport: (values: any) => void
  resetForm: () => void
  getCities: (prefId: string) => void
  closeClusterDialog: () => void
  readonly isOpen: boolean
  readonly isSuccess: boolean | null
  readonly isError: boolean | null
  readonly targetReport: IReportDetail | null
  readonly options: IFormOptions | null
}
type IProps = IEditReportProps & IStyles

const steps = ['Step1', 'Step2', 'Step3', 'Step4', '編集完了']

const getUsings = (usingCodes: string, options: IFormOption[]): any => {
  const usings: {
    using: string[]
    usingOther: string
  } = { using: [], usingOther: '' }
  if (usingCodes === '') {
    return usings
  }
  const codes = options.map(opt => {
    return opt.code
  })
  usingCodes.split(',').forEach(code => {
    if (codes.includes(code)) {
      const tmp = options.find(opt => {
        return opt.code === code
      })
      usings.using.push(tmp!.name)
    } else {
      usings.using.push('その他')
      usings.usingOther = code
    }
  })
  return usings
}

class EditReportDialog extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      step: 0,
    }
    this.nextStep = this.nextStep.bind(this)
    this.prevStep = this.prevStep.bind(this)
  }

  nextStep = () => {
    this.setState({
      ...this.state,
      step: this.state.step + 1,
    })
  }

  prevStep = () => {
    this.setState({
      ...this.state,
      step: this.state.step - 1,
    })
  }

  onSubmit = (values: any) => {
    this.props.updateReport(values)
    this.nextStep()
  }

  handleClose = (event: React.SyntheticEvent<{}, Event>) => {
    if (
      this.state.step > 3 ||
      window.confirm('編集フォームを閉じても良いですか？')
    ) {
      this.setState({
        ...this.state,
        step: 0,
      })
      this.props.closeDialog()
      this.props.closeClusterDialog()
    }
  }

  renderStep = (step: number) => {
    if (step > 3 && this.props.isSuccess) {
      this.props.resetForm()
      return (
        <div>
          <h2>投稿の編集が完了しました。</h2>
          <p>
            以上で編集は完了です。地図に投稿が反映されました。
            <br />
            自分の投稿を見るには、自分が投稿した地域の赤いマーカーをクリックするか、すべての投稿を読むボタンをクリックしてください。
          </p>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} style={{ textAlign: 'right' }}>
              <Button
                color="primary"
                variant="outlined"
                type="button"
                onClick={this.handleClose}
              >
                閉じる
              </Button>
            </Grid>
          </Grid>
        </div>
      )
    }
    if (step > 3 && this.props.isError) {
      return (
        <div>
          <h2>エラーが発生しました</h2>
          <p>
            更新に失敗しました。
            <br />
            時間をおいて試してください。
          </p>
        </div>
      )
    }
    const report = this.props.targetReport
    let initialValues = {}
    let imageUrl: string = ''
    if (report) {
      const usings = getUsings(report.using_codes, this.props.options!.using)
      let weather: string = ''
      let weatherOther: string = ''
      if (report.weather !== '' && report.weather === report.weather_code) {
        weather = 'other'
        weatherOther = report.weather
      } else {
        weather = report.weather_code
      }
      let hiveType: string = ''
      let hiveTypeOther: string = ''
      if (
        report.hive_type !== '' &&
        report.hive_type === report.hive_type_code
      ) {
        hiveType = 'other'
        hiveTypeOther = report.hive_type
      } else {
        hiveType = report.hive_type_code
      }
      let hiveLocation: string = ''
      let hiveLocationOther: string = ''
      if (
        report.hive_location !== '' &&
        report.hive_location === report.hive_location_code
      ) {
        hiveLocation = 'other'
        hiveLocationOther = report.hive_location
      } else {
        hiveLocation = report.hive_location_code
      }
      if (report.image) {
        imageUrl = report.image
      }
      initialValues = {
        nickname: report.nickname,
        catchDate: report.catch_date_raw,
        timezone: report.time_zone_code,
        pref: report.pref_id,
        city: report.city_id,
        weather,
        weatherOther,
        catchType: report.catch_type_code,
        hiveType,
        hiveTypeOther,
        hiveLocation,
        hiveLocationOther,
        using: usings.using,
        usingOther: usings.usingOther,
        description: report.description,
      }
    }
    switch (step) {
      case 0:
        if (report) {
          this.props.getCities(report.pref_id)
        }
        return (
          <FirstStep onSubmit={this.nextStep} initialValues={initialValues} />
        )
      case 1:
        return (
          <SecondStep
            onSubmit={this.nextStep}
            prevStep={this.prevStep}
            initialValues={initialValues}
          />
        )
      case 2:
        return (
          <ThirdStep
            onSubmit={this.nextStep}
            prevStep={this.prevStep}
            initialValues={initialValues}
            imageUrl={imageUrl}
          />
        )
      case 3:
        return (
          <FourthStep
            onSubmit={this.onSubmit}
            prevStep={this.prevStep}
            isEdit={true}
          />
        )
    }
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    )
  }

  render() {
    const { classes } = this.props
    const { step } = this.state
    let fullScreen = false
    if (window.innerHeight < 600) {
      fullScreen = true
    }
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.handleClose}
        aria-labelledby="report-dialog"
        disableBackdropClick={true}
        fullScreen={fullScreen}
        className={classes.root}
      >
        <DialogTitle id="report-dialog" onClose={this.handleClose}>
          編集
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Stepper activeStep={step} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {this.renderStep(step)}
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EditReportDialog)
